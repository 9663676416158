// APP

@mixin w-h-location-form-util-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);

  .location-form {
    .location-form-span {
      padding: 0 $padding__page 0 0;

      .is-exact-wrapper {
        color: #aaa;
        font-size: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 10px;
        min-width: 110px;
        width: 120px;
        gap: 5px;
      }

      .coordination-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;
      }

      .latitude-span {
        padding-right: $padding__page;
      }
      .longitude-span {
        padding-left: $padding__page;
      }

      .search-by-coords-span {
        color: #aaa;
        font-size: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 5px;
      }
    }

    agm-map {
      height: 474px;
    }

    .support-drag-n-drop-placeholder {
      border: 1px mat.get-color-from-palette($primary, 300) dashed;
      border-radius: 0px;
      height: 474px;
      background-color: mat.get-color-from-palette($primary, 50);
    }
  }
}
