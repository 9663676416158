@mixin reports-page-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);

  .reports-page {
    margin: 16px;

    .report-box {
      cursor: pointer;
      text-decoration: none;
      padding: 4px 9px;
      mat-icon.show-more {
        color: mat.get-color-from-palette($primary);
        transition: 0.3s;
        opacity: 0;
      }
      &:hover {
        background-color: var(--mat-menu-item-hover-state-layer-color);

        mat-icon.show-more {
          opacity: 1;
        }
      }

      .icon-holder {
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        border-radius: 10px;
        i {
          color: mat.get-color-from-palette($primary);
        }

        mat-icon {
          color: mat.get-color-from-palette($primary);
        }
      }

      .text-holder {
        display: inline-block;
        vertical-align: middle;
        width: 80%;
        h2 {
          color: mat.get-color-from-palette($primary);
          font-weight: 400;
          margin: 0px !important;
        }
        p {
          color: #999999;
          font-size: 16px;
          margin: 0px !important;
        }
      }
    }
  }
}
