// WORKHELD

@mixin check-instance-file-img-gallery-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);
  .check-instance-file-img-gallery {
    padding-top: 16px;
    border-top: 1px solid #e8e8e8;
    .check-instance-file-message {
      padding: $padding__page;
    }

    .check-instance-file-paginator {
      background: $theme-app-bar;
      .mat-paginator-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        min-height: 39px;
        padding: 0 8px;
        flex-wrap: wrap-reverse;
        width: 100%;
      }
    }

    .thumbnail-list-wrapper {
      min-height: 170px;

      .thumbnail-list-item-wrapper {
        max-width: calc(10% - 8px);
        display: block;
        margin: 4px;

        .mat-badge-content {
          top: 11px;
          font-size: 20px;
          width: 40px;
          height: 40px;
          line-height: 40px;
        }
        .thumbnail-wrapper {
          min-height: 130px;
          background: whitesmoke;
          text-align: center;
          cursor: pointer;
          padding: 4px;
          .thumbnail {
            display: inline-block;
            max-height: 136px;
            max-width: 100%;
          }

          .thumbnail:hover {
            transform: scale(1.05);
          }
        }
        .thumbnail-meta-wrapper {
          background: $theme-app-bar;
          padding: 4px;
          .file-name {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
          .download-img-icon {
            color: mat.get-color-from-palette($accent);
            vertical-align: middle;
            cursor: pointer;
            font-size: 18px;
            height: 18px;
            width: 20px;
          }
        }
      }
    }
  }
}
