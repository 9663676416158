// APP
@import "./w-h-work-step-assignment-chip/w-h-work-step-assignment-chip.component";

@mixin mat-dialog-work-step-entry-component-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);

  @include w-h-work-step-assignment-chip-mixin($theme);

  // TOP HEADER
  .new_detail-card-header {
    padding: 20px;
    display: flex;
    justify-content: space-between;

    .header-title-holder {
      display: flex;
      align-items: center;

      .mat-mdc-card-avatar {
        background-color: var(--primary-color-500);
        color: #fff;
        padding: 5px;
        display: grid;
        align-items: center;
        text-align: center;
      }

      h2 {
        margin: 0 0 0 10px;
      }

    }

    .text-right {
      .w-h-status-bar-wrapper {
        ol.w-h-status-bar {
          text-align: right;
        }
      }
    }

    .new_detail-actions-wrapper {
      button {
        margin-left: 10px;
        min-height: 38px;
      }

      .delete-button {
        margin-right: 0;
      }
    }
  }

  .work-step-mat-dialog {


    .w-h-label.desc-label {
      margin-bottom: 0;
    }

    .work-step-mat-dialog-body {
      padding: 15px 15px 0 15px;

      .assigned-items-container {
        background-color: #f4f4f4;
        border: 1px solid #e8e8e8;
        border-radius: 8px;
        flex: 1;
        display: flex;
        flex-direction: column;
        position: relative;
        padding: 12px;
        max-height: 500px;
        min-width: 280px;
        overflow-y: auto;
      }

      .planned-work-container {

        .text-right {
          text-align: right;
        }

        mat-form-field {
          width: 134.5px;
        }
      }
    }

    .assignment-body {
      padding-left: $padding__page;
    }

    .w-h-label.assignment-label {
      margin-bottom: unset;
    }

    .w-h-label.assignment-label.c-h-label {
      padding-left: unset;
      margin-bottom: unset;
    }

    .assignment-column {
      min-height: 264px;
      max-height: 264px;
      overflow-y: auto;
      overflow-x: hidden;
    }

    .assignment-column.checklistdefinition-column {
      min-height: 63px;
      max-height: 63px;
      overflow-y: hidden;
      overflow-x: hidden;
    }

    .assignment-column.eq-column {
      padding: $padding__page;
      min-height: 63px;
      max-height: 63px;
      overflow-y: hidden;
      overflow-x: hidden;
    }

    .mat-chip-list-assigned {
      ng-bee-w-h-work-step-assignment-chip {
        width: 100%;
      }
    }

    .mat-dialog-actions.dialog-action-wrapper {
      padding: 15px;
      min-height: unset;
    }

    .w-h-default-button.dialog-button {
      min-width: 128px;
      max-width: 45%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .mat-accent.dialog-button {
      min-width: 128px;
      max-width: 45%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}