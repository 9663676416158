// APP

@mixin entry-unassigned-overview-mixin($theme) {
    $primary: map-get($theme, color, primary);
    $accent: map-get($theme, color, accent);
    $warn: map-get($theme, color, warn);
    .entry-unassigned-overview-card {
        border-radius: 0px;
        padding: 0px;
        margin: $padding__page;

        ng-bee-entry-data-table.subsidiary-entry-data-table {
            .entry-data-table-container .data-table-container {
                min-height: 258px;
                .data-table {
                  min-height: calc(100vh - 314px);
                }
            }
        }
        ng-bee-entry-file-data-table.subsidiary-entry-data-table .entry-file-data-table {
            .entry-file-data-table-container .data-table-container {
                min-height: calc(100vh - 624px);
                .data-table {
                    min-height: calc(100vh - 314px);
                }
            }
        }

        ng-bee-report-file-data-table.subsidiary-entry-data-table {
            .report-file-data-table-container .data-table-container {
                min-height: calc(100vh - 624px);
                .data-table {
                    min-height: calc(100vh - 314px);
                }
            }
        }

        ng-bee-w-h-file-export-button-util.entry-unassigned-overview-w-h-file-export-button-util
            .w-h-file-export-button-wrapper {
            .w-h-export-button {

            }
        }
    }
}


.unassigned-entries-export-buttons-grid {
    justify-content: center;
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(auto-fill, 350px);
  
    @media (max-width: 1555px) {
      grid-template-columns: repeat(auto-fill, 480px);
    }
  
    @media (max-width: 600px) {
      grid-template-columns: repeat(auto-fill, 300px);
    }
  }