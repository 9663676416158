// APP

@mixin work-order-crud-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);
  .work-order-crud {
    padding: 0px;
    max-height: calc(100vh - 62px);
    overflow: auto;
  }
}
