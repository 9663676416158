// APP

@mixin mat-dialog-work-object-reject-entry-component-mixin($theme) {
    $primary: map-get($theme, color, primary);
    $accent: map-get($theme, color, accent);
    $warn: map-get($theme, color, warn);

    .work-object-reject-mat-dialog {
         

        .work-object-reject-mat-dialog-body {
            padding: $padding__page;
        }

        .mat-dialog-actions.dialog-action-wrapper {
            margin: 0px 0px 0px 0px;
            padding: 0px;
            min-height: unset;
        }
        .w-h-default-button.dialog-button {
            max-width: 45%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        .mat-accent.dialog-button {
            max-width: 45%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}
