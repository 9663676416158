// APP

@mixin mat-dialog-contact-entry-component-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);

  .contact-mat-dialog {
    .mat-mdc-card-avatar.workheld-icons.header-icon {
      border-radius: 0px;
      height: 48px;
      width: 64px;
      text-align: center;
      line-height: 1.5;
      font-size: 34px;
      background-color: mat.get-color-from-palette($primary, 500);
      color: #fff;
    }

    mat-error {
      position: absolute;
    }

    .w-h-title {
      color: mat.get-color-from-palette($primary, 500);
      font-weight: 600;
      padding-top: 5px;
      font-size: 13px;
    }

    .contact-mat-dialog-body {
      padding: 16px;
    }

    .mat-dialog-actions.dialog-action-wrapper {
      padding: 15px;
      min-height: unset;
    }

    .w-h-default-button.dialog-button {
      max-width: 45%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .mat-accent.dialog-button {
      max-width: 45%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

mat-form-field {
  width: 100%;
}