// APP

@mixin w-h-dnd-placeholder-util-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);

  .w-h-dnd-placeholder-util {
    border: 1px mat.get-color-from-palette($primary, 300) dashed;
    border-radius: 0px;
    height: 293px;
    background-color: mat.get-color-from-palette($primary, 50);

    .placeholder-text {
      align-self: center;
      text-align: center;
    }
  }
}
