// APP

@mixin mat-dialog-preview-entry-component-mixin($theme) {
    .preview-mat-dialog {
        .preview-mat-dialog-header {
            margin-bottom: 0;
        }

        w-h-material-preview.mat-preview-dialog .w-h-material-preview {
            .mat-mdc-table.w-h-data-table {
                width: 100%;
                min-height: 614px;
            }
        }

        .workheld-icons.dialog-close-icon {
            cursor: pointer;
        }

        .w-h-button-ctrl-group {
            padding: 16px;
        }

    }
}