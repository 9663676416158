@mixin mat-dialog-unsaved-changes-component-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);
  .modal-wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 16px;
    .modal-header {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 16px;
      mat-icon {
        width: 28px;
        height: 28px;
        font-size: 28px;
        color: white;
        box-shadow: 1px 1px 6px rgb(0 0 0 / 49%);
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: mat.get-color-from-palette($primary);
        border-radius: 50%;
        padding: 12px;
      }
    }

    .modal-body {
      text-align: left;
      h2 {
        font-weight: 600;
        margin: 0px;
      }
      p {
        margin: 0px;
      }
    }

    .modal-actions {
      display: flex;
      justify-content: flex-end;
      flex-direction: row;
      gap: 12px;
    }
  }
}
