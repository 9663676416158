$info-color-bg: #deebff;
$info-color-text: #172b4d;

.messages-container {
  background-color: #f4f4f4;
  border-radius: 4px;
  padding: 8px;

  mat-icon {
    overflow: unset !important;
  }
  &.info {
    background-color: $info-color-bg;
    color: $info-color-text;
  }

  &.warn {
    background-color: var(--warn-color-100);
    color: var(--warn-color-500);
  }

  button {
    all: unset;
    font-weight: 600;
    cursor: pointer;
    text-decoration: underline;
  }
}
