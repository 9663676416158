// APP

@mixin project-crud-mixin($theme) {

  .project-crud-edit-form-card {
      padding: 0px;
      border-radius: 0px;
      margin: 16px;
  }

  .project-detail {
    padding: 0px;
    max-height: calc(100vh - 62px);
    overflow: auto;
  }
}
