// APP

@mixin check-definition-create-edit-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);
  .check-definition-create-edit-card {
    padding: 0px;
    border-radius: 0px;
    width: 100%;
    
    mat-form-field {
      width: 100%;
    }
    form {
      width: 100%;
      align-items: flex-start;
      margin: 12px 0px;
      gap: 12px;

    }

    .check-icon-span {
      text-align: center;
      padding-top: 12px;
    }

    .mandatory-span {
      text-align: center;
      height: 57px;
      display: flex;
      align-items: center;
    }

    .sort-position {
      background-color: mat.get-color-from-palette($accent);
      width: 34px;
      height: 34px;
      border-radius: 50%;
      color: mat.get-color-from-palette($accent, default-contrast);
      text-align: center;
      line-height: 2;
      font-size: 18px;
      font-weight: 600;
    }
  }
}
