// APP

@import "./work-object-status-overview-board/work-object-status-overview-board.component";
@import "./work-object-status-column/work-object-status-column.component";

@mixin work-object-status-overview-mixin($theme) {
  @include work-object-status-overview-board-mixin($theme);
  @include work-object-status-column-mixin($theme);
  .work-object-status-overview-card {
    margin: 16px;
    padding: 0px;
    border-radius: 0px;

    mat-tab-group {
      height: calc(94vh - 100px);

      .tab-header-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 12px;
        span {
          font-weight: 600;
        }
      }
    }

    .mat-tab-body-content {
      overflow-anchor: none;
    }
  }
  .welcome-screen {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 32px;
    box-sizing: border-box;
    height: 100%;

    .welcome-screen-content {
      display: flex;
      flex-direction: column;
      gap: 32px;
      align-items: center;

      .header {
        img {
          max-width: 80%;
        }
      }

      .body {
        .hand {
          font-size: 32px;
        }
        .left-off-message {
          font-weight: 400;
          margin: 0;
          text-align: center;
        }

        h2 {
          color: var(--primary-color-500);
          font-weight: 600;
        }
      }
    }
  }
}
