// APP

@mixin worker-crud-page-mixin($theme) {
    $primary: map-get($theme, color, primary);
    $accent: map-get($theme, color, accent);
    $warn: map-get($theme, color, warn);
  
    .worker-crud-page {
        padding: $padding__page;
        // .page-card-content {
        //     padding: 0;
        // }
        .page-card-content-wrapper {
            min-height: calc(100vh - 260px);
        }
    }
  }