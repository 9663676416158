// APP

@mixin mat-dialog-contact-customer-entry-component-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);

  .contact-customer-mat-dialog {
    .mat-mdc-row.selected-template {
      background-color: mat.get-color-from-palette($primary, 200);

      .mat-mdc-cell {
        color: #fff;
      }
    }

    .contact-customer-mat-dialog-body {
      padding: 24px;

      .mat-stepper-horizontal.use-template-stepper {
        .mat-horizontal-content-container {
          overflow: hidden;
          padding: 0;
        }
      }

      .select-template-msg {
        text-align: center;
        vertical-align: middle;
        min-height: 559px;
      }

      .mat-selection-list.work-order-selection-list {
        min-height: 404px;
        max-height: 404px;
        overflow-y: auto;
        margin-bottom: $padding__page;
      }

      .dialog-action-wrapper {
        padding: 0 $padding__page $padding__page $padding__page;
      }

      .selected-work-object-template {
        padding: 0 $padding__page;
      }

      .agm-core-location-form-wrapper {
        padding: 0 $padding__page;
        margin-bottom: 28px;
      }

      .w-h-label.desc-label {
        margin-top: 4px;
        margin-bottom: 0;
      }

      .mat-radio-label {
        display: flex;
        flex-direction: column;
        margin: 5px 10px;

        .mat-radio-label-content {
          padding: 0;
        }
      }

      .mat-radio-ripple {
        display: none;
      }

      .contact-person-detail {
        padding-top: 0px !important;
        border-right: 1px solid #e3e3e3;
        padding-right: 16px;
        .w-h-label.workobject-desc {
          margin-bottom: 0px;
        }

        .contact-person-wrapper {
          margin-bottom: $padding__page;
        }

        .contact-box {
          .contact-field {
            line-height: 1;
            min-height: 64px;
            border: 1px solid #e3e3e3;
            border-radius: 8px;
            margin-bottom: $padding__page;
            padding: 4px 0;
          }

          .workheld-icons.contact-icon {
            color: mat.get-color-from-palette($accent);
            font-size: 24px;
          }
        }
      }

      .warn-text {
        color: $operatingStateId2;
      }
    }

    .mat-dialog-actions.dialog-action-wrapper {
      padding: 15px;
      min-height: unset;
    }

    .w-h-default-button.dialog-button {
      max-width: 45%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .mat-accent.dialog-button {
      max-width: 45%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .w-h-dialog-button {
      margin-right: $padding__page;
    }
  }
}
