// APP

@mixin mat-dialog-work-step-scheduler-entry-component-mixin($theme) {
    $primary: map-get($theme, color, primary);
    $accent: map-get($theme, color, accent);
    $warn: map-get($theme, color, warn);

    .work-step-scheduler-mat-dialog {
         

        .work-step-scheduler-mat-dialog-body {
            padding: $padding__page;
        }

        .mat-dialog-actions.dialog-action-wrapper {
            padding: 0px 15px 15px 15px;
            min-height: unset;
        }
        .w-h-default-button.dialog-button {
            min-width: 128px;
            max-width: 45%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        .mat-accent.dialog-button {
            min-width: 128px;
            max-width: 45%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}
