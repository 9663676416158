$sizes: (
  4: 4,
  8: 8,
  12: 12,
  16: 16,
  24: 24,
  28: 28,
  32: 32,
);

.w-100 {
  width: 100%;
}

.min-w-100 {
  min-width: 100%;
}

@each $space-size, $size in $sizes {
  .m-t--#{$space-size} {
    margin-top: #{$size}px;
  }

  .m-r--#{$size} {
    margin-right: #{$size}px;
  }

  .m-b--#{$size} {
    margin-bottom: #{$size}px;
  }

  .m-l--#{$size} {
    margin-left: #{$size}px;
  }

  .p-t--#{$size} {
    padding-top: #{$size}px;
  }

  .p-r--#{$size} {
    padding-right: #{$size}px;
  }

  .p-b--#{$size} {
    padding-bottom: #{$size}px;
  }

  .p-l--#{$size} {
    padding-left: #{$size}px;
  }

  .m--#{$size} {
    margin: #{$size}px;
  }

  .p--#{$size} {
    padding: #{$size}px;
  }
}
