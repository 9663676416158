// APP

@mixin material-usage-report-page-mixin($theme) {
    $primary: map-get($theme, color, primary);
    $accent: map-get($theme, color, accent);
    $warn: map-get($theme, color, warn);

    .material-usage-report {
        padding: 16px;
        ng-bee-w-h-time-span-header-util.material-usage-report-timeheader header.w-h-time-span-header {
            .w-h-time-span-card {
                box-shadow: none;
            }
            .w-h-time-span-toolbar {
                min-height: unset;
                padding: unset;
            }
        }

        .selected-departments {
            font-size: 12px;
            position: absolute;
            bottom: 13px;
        }

        .export-button-span {
            display: inline-block;
            .w-h-file-export-button-wrapper {
              padding: 0px $padding__page;
              .w-h-file-export-materialusagereport {
                .w-h-export-button {
                  padding: 0px $padding__page;
                  border-radius: 4px;
                  .workheld-icons.export-icon {
                    vertical-align: middle;
                    width: 34px;
                    height: 24px;
                    font-size: 24px;
                  }
                  .materialusagereport-label {
                    padding: 4px;
                  }
                  mat-spinner {
                    display: inline-block;
                  }
                }
              }
            }
          }

        .material-usage-body {
            position: relative;
            min-height: calc(100vh - 342px);
        }

        .material-usage-loading-shade {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background: rgba(0, 0, 0, 0.15);
            z-index: 1;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .material-usage-no-data {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background: #fff;
            z-index: 1;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .header-box {
            text-align: center;
            height: 34px;
            background-color: #ddd;
            margin: 0px 8px;
        }

        .column-border {
            border-left: 2px dashed #aaa;
        }

        .workheld-icons.header-box-icon {
            margin-top: 8px;
        }

        .text-right {
            text-align: right;
        }

        .table {
            width: 40%;
            text-align: left;
            tr {
                th, td {
                    border-bottom: 1px solid #cccccc;
                    line-height: 26px;
                }
            }
        }

        .text-center {
            text-align: center;
            background-color: #bbb;
            height: 27px;
            margin: 0px 8px;
            line-height: 1.7;
        }

        .column-wrapper-span {
            height: 35px;
            border-left: 2px dashed #aaa;
        }

        material-usage-report-body-card {
            border-radius: 0px;
            padding: 16px 0px;
            min-height: calc(100vh - 266px);
        }

        .workheld-icons.material-usage-report-icon {
            font-size: 20px;
            vertical-align: sub;
        }

        .work-line {
            line-height: 2.2;
            min-height: 35px;
            cursor: pointer;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .work-line.work-line-p {
            padding-right: 4px;
        }

        .work-line.unassign-line {
            cursor: unset;
        }

        .work-line.work-object-line {
            background-color: mat.get-color-from-palette($primary);
            color: #fff;
        }

        .work-order-line {
            background-color: #ddd;
            height: 35px;
            margin: 0px 8px;
        }

        .work-step-box {
            height: 35px;
            background-color: #ddd;
        }

        .no-color-value {
            height: 8px;
            background-color: #fff;

            .column-extra {
                height: 8px;
                margin: 0px 8px;
                background-color: #ddd;
            }
        }

        .work-step-name {
            line-height: 1.7;
            background-color: #ddd;
            padding-right: 2px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}