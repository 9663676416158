// APP

@mixin w-h-material-list-mixin($theme) {
    $primary: map-get($theme, color, primary);
    $accent: map-get($theme, color, accent);
    $warn: map-get($theme, color, warn);

    .w-h-material-list {

        .mat-mdc-table.data-table {
            min-height: calc(100vh - 498px);
        }
    }

}
