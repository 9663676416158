// APP

@mixin standing-order-crud-mixin($theme) {
    .standing-order-crud-edit-form-card {
        padding: 0px;
        border-radius: 0px;
        margin: $padding__page;
    }

    .standing-order-detail {
        padding: 0px;
        max-height: calc(100vh - 62px);
        overflow: auto;
    }
}
