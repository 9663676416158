// APP

@mixin w-h-event-log-data-table-mixin($theme) {
    $primary: map-get($theme, color, primary);
    $accent: map-get($theme, color, accent);
    $warn: map-get($theme, color, warn);
    .w-h-event-log-data-table {
        // padding: $padding__page;
        .mat-mdc-header-row.w-h-header-row {
            min-height: 40px;
        }

        .mat-paginator.paginator {
            display: block;
            background: $theme-app-bar;
            min-height: 56px;
            .mat-paginator-container {
                min-height: 56px;
            }
            .mat-paginator-page-size-select {
                margin: 0px 4px 0 4px;
                width: 56px;
            }
        }
    }
}
