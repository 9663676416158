// APP

@mixin w-h-bom-node-crud-form-mixin($theme) {
    $primary: map-get($theme, color, primary);
    $accent: map-get($theme, color, accent);
    $warn: map-get($theme, color, warn);

    .page-card.w-h-bom-node-crud-form {
        min-height: calc(100vh - 144px);
        margin: $padding__page;

        .detail-card-header.node-create-edit-card-header {
            padding: 0px;
        }

        .image-wrapper-span {
            padding-right: $padding__page;
        }

        .node-crud-button {
            margin-left: 16px;
        }

        .map-form-wrapper {
            min-height: calc(100vh - 340px);
        }
        .flex-property-wrapper {
            min-height: calc(100vh - 340px);
        }
    }
}
