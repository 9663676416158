// APP

@mixin work-object-static-data-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);

  .work-object-static-data-grid {

    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    justify-content: center;
  }

  .workheld-icons.w-h-info-icon {
    display: inline-flex;
    vertical-align: sub;
    margin-left: $padding__page;
  }
}