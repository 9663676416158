// APP
@mixin ng-bee-list-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);

  .ng-bee-list {
    @include mat.elevation(2);

    .list-header {
      background: $theme-card;
      padding-right: $padding__page;
      border-bottom: 1px solid $theme-divider;
      height: 64px;

      .actions-wrapper:empty {
        display: none;
      }

      .list-name {
        font-size: 18px;
        color: $theme-text;
        padding-top: 11.5px;
        padding-left: 11.5px;
        padding-right: 11.5px;
        border-right: 1px solid $theme-divider;
        align-self: center;
        height: 100%;
        background-color: mat.get-color-from-palette($primary, 500);

        .workheld-icons.list-icon {
          font-size: 40px;
          height: 40px;
          width: 40px;
          color: mat.get-color-from-palette($primary, default-contrast);
        }
        .mat-icon.list-icon {
          font-size: 40px;
          height: 40px;
          width: 40px;
          color: mat.get-color-from-palette($primary, default-contrast);
        }
      }

      .filter-input {
        padding-left: $padding__page;
        .filter-input-icon {
          color: $theme-secondary-text;
          vertical-align: bottom;
        }
        & .cancel-icon {
          cursor: pointer;
        }
      }

      .column-filter {
        margin-left: $padding__page;
        color: $theme-secondary-text;
      }

      .create {
        margin-left: $padding__page;
      }
    }

    .mat-mdc-header-row {
      background: $background_color__data-table-elements;
    }

    .mat-mdc-cell.image-cell,
    .mat-mdc-header-cell.image-cell {
      flex: 0;
      min-width: 46px + $padding__page;

      .mat-icon {
        height: 35px;
        width: 35px;
        font-size: 35px;
      }

      img {
        border-radius: 0px;
        height: 35px;
        width: 35px;
        margin-right: $padding__page;
        vertical-align: middle;
      }
    }

    .mat-mdc-cell.actions-cell,
    .mat-mdc-header-cell.actions-cell {
      flex: 0;
      min-width: 40px;
      overflow: visible;

      button {
        color: $theme-icon;
      }
    }
  }
}
