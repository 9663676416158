@mixin hrworker-create-edit-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);

  .hrworker-create-edit-form {
    height: 100%;
  }

  .hrworker-create-edit-card {
    margin: $padding__page;
    min-height: 95%;
    //height: 95%;
  }

  .hrworker-detail-card-body {
    padding: $padding__page;
    //min-height: calc(100vh - 244px);

    .title {
      color: mat.get-color-from-palette($primary, 500);
      font-weight: 600;
    }

    .data-table-toolbar {
      padding: $padding__page 0px $padding__page 0px;
    }

    .mat-column-extId {
      div {
        display: flex;
        align-items: center;
      }
    }

    .mat-row {
      width: 100%;
      text-align: center;

      .table-no-data {
        padding: 16px;
      }
    }

    .table-filter-padding {
      padding-left: 16px;
    }
  }
}

.th-right {
  text-align: right;
}
