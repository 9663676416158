// APP

@mixin mat-dialog-checklist-instance-entry-component-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);

  .scroll-input {
    background-color: unset;
    border: unset;
    width: 100%;
  }

  .checklist-instance-mat-dialog {
    .checklist-instance-detail-mat-dialog-body {
      padding: $padding__page;
      padding-bottom: 0px;

      .instance-icon-wrapper {
        max-width: 64px;
      }
    }

    .check-list-wrapper {
      max-height: 376px;
      overflow: auto;
      padding-right: 16px;
    }

    .check-body {
      display: grid;
      gap: 16px;
      grid-template-columns: 3% 30% 20% 20% 20%;
    }

    .checklist-instance-action-wrapper {
      text-align: end;
      max-width: 56px;
    }

    .sort-position.check-instance-pos {
      background-color: mat.get-color-from-palette($primary);
      min-width: 34px;
      max-width: 34px;
      min-height: 34px;
      max-height: 34px;
      border-radius: 50%;
      color: mat.get-color-from-palette($primary, default-contrast);
      text-align: center;
      line-height: 2;
      font-size: 18px;
      font-weight: 600;
      margin-top: 8px;
      margin-right: $padding__page;
    }

    .mat-dialog-actions.dialog-action-wrapper {
      padding: 0px $padding__page $padding__page $padding__page;
      min-height: unset;
    }

    .w-h-default-button.dialog-button {
      max-width: 45%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .mat-accent.dialog-button {
      max-width: 45%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
