// WORKHELD

@mixin w-h-mat-date-picker-util-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);

  .mat-hint.red {
    color: red;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .w-h-mat-date-picker-util {
    .time-picker {
      width: 126px;
      margin-right: $padding__page;

      mat-icon {
        font-size: 24px !important;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .clear-icon-wrapper {
      vertical-align: middle;

      .workheld-icons.clear-icon {
        font-size: 20px;
      }

      .workheld-icons.clear-icon.date-set {
        color: mat.get-color-from-palette($accent);
        cursor: pointer;
      }
    }
  }
}


.container-picker {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}