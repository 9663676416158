// APP

@mixin w-h-time-span-header-util-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);
  header.w-h-time-span-header {
    padding: 0px;
    // background: url(~bryntum-resources/images/logo_bryntum_bw.png) #2567c8;
    // background: #2567c8;
    background-repeat: no-repeat;
    background-size: 30px;
    background-position: center left 1.5em;
    display: flex;
    align-items: center;

    .mat-button-toggle.mat-button-toggle-appearance-standard.ng-star-inserted.mat-button-toggle-checked {
      background-color: mat.get-color-from-palette($accent);
      color: white !important;
    }

    .w-h-time-span-card {
      border-radius: 0px;
      width: 100%;
      min-width: 740px;
      padding: 10px;

      .w-h-time-span-toolbar {
        padding: 4px $padding__page;

        .time-span-control-button {
          min-height: 48px;
          min-width: 64px;

          &.next {
            display: flex;
            flex-direction: row-reverse;
            gap: 4px;

            mat-icon {
              margin: 0px !important;
            }
          }

          .workheld-icons.time-span-control-icon {
            color: mat.get-color-from-palette($accent);
          }
          .material-icons.time-span-control-icon {
            color: mat.get-color-from-palette($accent);
          }
        }

        .w-h-time-span-toolbar-control-wrapper {
          max-width: 66.66%;
          .time-span-control {
            border: solid 1px #ddd;
            border-radius: 4px;
            height: 48px;
          }

          .time-span-control-date {
            text-align: center;
            line-height: 3;
            min-width: 280px;
            padding-left: 8px;
            padding-right: 8px;
          }
          .time-span-control-date.back-to-current-date:hover {
            text-align: center;
            line-height: 3;
            background-color: mat.get-color-from-palette($accent, 100);
            color: mat.get-color-from-palette($accent, 500);
            cursor: pointer;
          }
        }
      }
    }

    h1 {
      margin: 0;
      font-size: 1.8em;
      color: #fff;
      font-weight: normal;
      flex: 1;
      align-self: center;
    }

    a {
      color: inherit;
      text-decoration: none;
    }
  }

  .mat-button-toggle-checked {
    background-color: mat.get-color-from-palette($accent, 500) !important;
    color: white !important;
  }
}

.more-actions-btn-left {
  border-right: 1px solid var(--accent-color-200);
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.more-actions-btn-right {
  mat-icon {
    margin: 0px;
  }
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
