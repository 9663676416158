// APP

@mixin w-h-material-preview-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);

  .material-data-table-container {
    padding: 16px;
  }

  .reservation-ticket {
    padding: 16px;
    background: mat.get-color-from-palette($primary);
    width: max-content;
    height: 66px;
    position: relative;
    color: mat.get-color-from-palette($primary, 50);
    border-radius: 8px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }

  .disabled-row {
    background-color: #f4f4f4 !important;
    opacity: 0.7;
  }

  .loading-materials {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
  }
}

mat-header-row {
  background-color: whitesmoke;
}
