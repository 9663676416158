@mixin shift-calendar-detail-mixin($theme) {
    $primary: map-get($theme, color, primary);
    $accent: map-get($theme, color, accent);
    $warn: map-get($theme, color, warn);
    
    .shift-calendar-detail-card {
      margin: $padding__page;
      min-height: 95%;
    }
    
  
    mat-icon.header-icon {
      color: white;
    }
  
  }
  
  