// APP

@mixin standing-order-detail-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);

  .standing-order-detail-span {
    max-height: calc(100vh - 112px);
    overflow: auto;
  }

  .standing-order-detail-card {
    padding: 0px;
    border-radius: 0px;
    margin: $padding__page;

    ng-bee-worker-static-data.work-object-resp-tech {
      .worker-chip {
        max-width: 96%;
      }
    }

    // TOP HEADER
    .standing-order-detail-card-header {
      .new_detail-actions-wrapper {
        display: flex;
        align-items: center;

        button {
          mat-icon {
            margin: 0 !important;
          }
        }
      }

      // padding: 16px 8px;
    }

    .work-order-summary-label-row {
      margin-top: 8px;
    }

    .work-object-summary-wrapper {
      overflow-x: hidden;
      border: 1px solid transparent;
    }

    // BODY DATA
    .standing-order-detail-card-body {
      padding: $padding__page;
      display: flex;
    }

    .standing-order-detail-wrapper {
      padding: $padding__page;
    }
  }

  ng-bee-work-order-master-data-table.work-object-work-order-master-data-table {
    .ng-bee-list {
      margin-bottom: 0px;
    }
  }
}

.column-flex-min-width {
  flex: 33%;
  min-width: 33%;
}

::ng-deep {
  .mat-mdc-tab-body-content {
    overflow: hidden !important;
  }
}
