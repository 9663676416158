// APP

@mixin worker-management-page-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);

  .worker-management-page {
    padding: $padding__page;
    // .page-card-content {
    //     padding: 0;
    // }
    .mat-mdc-cell.image-cell,
    .mat-mdc-header-cell.image-cell {
      flex: 0;
      min-width: 35px + $padding__page;

      .mat-icon {
        height: 35px;
        width: 35px;
        font-size: 35px;
      }

      img {
        border-radius: 0px;
        height: 35px;
        width: 35px;
        margin-right: $padding__page;
        vertical-align: middle;
      }
    }

    .mat-mdc-cell.w-h-reset-pw {
      justify-content: flex-end;
    }
    .mat-mdc-row:hover {
      .mat-stroked-button.w-h-reset-pw-button {
        background-color: #fff;
      }
    }

    .mat-mdc-cell.nav-cell,
    .mat-mdc-header-cell.nav-cell {
      flex: 0;
      min-width: 40px;

      .mdc-icon-button {
        border-radius: 50%;
        height: 40px;
        width: 40px;
        vertical-align: middle;
      }
    }
  }
}
