@mixin mat-dialog-add-equipment-node-component-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);

  .add-equipment-node-dialog {
    display: flex;
    flex-direction: column;
    height: 100%;
    .data-table {
      min-height: unset !important;
    }
    .wh-equipment-node-paginator {
      min-width: 346px;
    }

    .add-equipment-node-dialog-body {
      display: flex;
      flex-direction: row;
      height: 94%;
      gap: 16px;

      .no-data {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .active-equipment {
        background-color: mat.get-color-from-palette($primary, 100);
      }

      .bomstructure-wrapper {
        position: relative;
        height: 100%;
        overflow-y: auto;
      }
      .mat-mdc-cell.image-cell,
      .mat-mdc-header-cell.image-cell {
        img {
          border-radius: 50%;
          height: 25px;
          width: 25px;
          margin-right: 0px;
        }
      }

      .left {
        .ng-bee-list {
          position: relative;
          height: 100%;
          display: flex;
          flex-direction: column;
          .data-table {
            flex: 1;
          }
        }
      }

      .right {
        display: flex;
        flex-direction: column;
        background-color: white;
        overflow-y: auto;
        overflow-x: hidden;
        width: 65vw;
        border-left: 1px solid #e8e8e8;
        .table-top-menu {
          position: sticky;
          top: 0;
          z-index: 10;
          height: 44px;
          display: flex;
          background-color: #fafafa;
        }

        .equipment-tree-node-container {
          display: flex;
          width: 100%;
        }

        .node-img-style {
          min-width: 24px;
          max-width: 24px;
          margin-right: 16px;
          max-height: 24px;
          display: flex;
          align-self: center;
        }

        .node-thumbnail-style {
          min-width: 24px;
          max-width: 24px;
          margin-right: 16px;
        }

        .node-icon-style {
          margin-right: 16px;
          display: flex;
          align-self: center;
        }
      }

      .matTreeNodeRow:hover {
        background-color: mat.get-color-from-palette($primary, 100);
        cursor: pointer;
      }

      .selectedBomNode {
        background-color: mat.get-color-from-palette($primary, 200);
      }
    }
  }
}
