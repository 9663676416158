@mixin qualification-detail-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);
  .qualification-detail-card {
    margin: $padding__page;
    height: 95%;
  }

  .qualification-detail-card-body {
    padding: $padding__page;
  }
}

mat-icon.header-icon {
  color: white;
}
