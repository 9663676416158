@mixin shift-model-group-mixin($theme) {
    $primary: map-get($theme, color, primary);
    $accent: map-get($theme, color, accent);
    $warn: map-get($theme, color, warn);


    .shift-model-group-container {
        background-color: #f4f4f4;
        border: 1px solid #e8e8e8;
        border-radius: 10px;
        padding: $padding__page;
        align-items: center;
    }

    .shift-model-group-header {
        padding-bottom: 16px;
    }

    .text-center {

        text-align: center;

    }

    .white-icon {
        color: #fff;
    }

    .extid-options-cell {
        justify-content: flex-end;
        display: flex;
        flex-direction: column;
        width: 50px;
    }

    .mat-mdc-cell.options-cell,
    .mat-mdc-header-cell.options-cell {
        flex: 0;
        justify-content: space-between;

        min-width: 60px;

    }

    .mat-mdc-cell.extid-cell,
    .mat-mdc-header-cell.extid-cell {
        flex: 0;
        min-width: 40px;
        padding-left: 5px;
    }


    .mat-mdc-cell.empty-cell {
        justify-content: center;
    }


    .button-text-color {
        color: #fff
    }

    .prevent-click {
        pointer-events: none;
    }

    .authorize-click {
        pointer-events: auto;
    }

}