// APP

@mixin project-template-detail-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);
  .project-template-detail-card {
    padding: 0px;
    border-radius: 0px;
    margin: $padding__page;

    .template-w-order-title-span {
      width: calc(100% - 62px);
    }

    .project-template-detail-card-body {
      padding: $padding__page $padding__page 0px $padding__page;
    }

    .template-label {
      font-size: 12px;
      margin-top: 4px;
      margin-bottom: 2px;
    }

    .project-template-work-order-list-wrapper {
      overflow: auto;
      min-height: 256px;
      max-height: calc(100vh - 440px);
      // margin-bottom: $padding__page;
      padding: $padding__page;

      .workheld-icons.template-list-icon {
        padding: 7px $padding__page;
        font-size: 30px;
        color: mat.get-color-from-palette($accent);
      }
      
      .workheld-icons.template-list-icon.checklist-icon {
        padding: 10px $padding__page;
        font-size: 24px;
        cursor: pointer;
      }

      .workheld-icons.template-list-icon.checklist-icon.icon-disabled {
        padding: 10px $padding__page;
        color: #bbb;
        font-size: 24px;
        cursor: not-allowed;
      }

      .workheld-icons.template-list-icon.work-step-icon {
        padding: 10px $padding__page;
        font-size: 24px;
      }

      .workheld-icons.template-list-icon.work-step-icon.icon-disabled {
        padding: 10px $padding__page;
        color: #bbb;
        font-size: 24px;
      }


      .work-order-template-wrapper {
        border: 1px solid #ddd;
        margin-bottom: $padding__page;

        .work-order-detail {
          min-height: 44px;
          background-color: #dfdfdf;
        }

        .work-step-template-wrapper:nth-child(odd) {
          .work-step-detail {
            background: #dfdfdf;
          }
        }
      }
      .work-order-template-wrapper:last-of-type {
        margin-bottom: 0;
      }
    }

    .detail-actions-wrapper.project-template-detail-actions-wrapper {
      padding: $padding__page;
    }
  }
}
