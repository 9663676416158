// APP

@mixin case-quick-create-mixin($theme) {
    .eq-quick-create {
        border-radius: 0px;
        margin: $padding__page;
        min-height: calc(100vh - 180px);

        .avatar-wrapper {
            text-align: center;
        }
        .dropdown.eq-dropdown-new.open {
            transform: translateY(0) scale(1);
            visibility: visible;
            width: 40%;
            max-width: 40%;
        }
    }

    .eq-wrapper {
        margin-bottom: 16px;
    }

    .category-controls {
        padding-top: 16px;
    }

    .select-info-span {
        width: calc(100% - 70px);
    }

    .w-h-category-button {
        padding: $padding__page;
        border-radius: 16px;
        width: 80%;
        height: 128px;
    }
}
