// APP

@mixin skills-detail-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);
  
  .skills-detail-card {
    margin: $padding__page;
    min-height: 95%;
  }
  
  .skills-detail-card-body {
    padding: $padding__page;
    //min-height: calc(100vh - 244px);
  }

  mat-icon.header-icon {
    color: white;
  }

}

