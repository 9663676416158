// APP

@mixin material-inventory-overview-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);

  $prefix: "worker-dropdown";

  .inventory-item-master-data-table {
    padding: $padding__page;

    .inventory-item-master-data-table-container {

      .list-header {
        height: 80px;
        padding-right: 0px;

        .filter-input {
          min-height: 44px;
          max-height: 44px;
          align-self: center;
        }
      }

      .data-table.mat-mdc-table {
        min-height: calc(100vh - 280px);
      }
    }

    .data-table-loading-shade.material-inventory-no-data-overlay {
      background: rgba(255, 255, 255, 0.15);
    }
  }
}