// APP

@mixin checklist-definition-detail-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);
  .checklist-definition-detail-card {
    padding: 0px;
    border-radius: 0px;
    margin: $padding__page;

    .checklist-definition-detail-card-body {
      padding: $padding__page;
      min-height: calc(100vh - 276px);

      .w-h-label.ch-def-description-label {
        margin-bottom: 0;
      }
    }

    .check-definition-array-wrapper {
      min-height: unset;
    }

    .ch-section-header {
      padding: $padding__page;
    }
    textarea {
      line-height: normal;
    }
  }
}
