// WORKHELD

@mixin entry-data-table-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);

  .entry-data-table {
    .entry-data-table-container {
      .data-table-container {
        .mat-mdc-table.data-table {
          width: 100%;
        }
      }

      .create.no-filter:not([disabled]) {
        border-color: mat.get-color-from-palette($accent, 500);
        background-color: mat.get-color-from-palette($accent, 500);
        color: mat.get-color-from-palette($accent, 50);
      }

      .filter-active:not([disabled]) {
        border-color: mat.get-color-from-palette($accent, 500);
        background-color: mat.get-color-from-palette($accent, 500);
        color: mat.get-color-from-palette($accent, 50);
      }

      .data-table-toolbar {
        height: 64px;
        padding-right: $padding__page;

        .right-side-wrapper {
          display: inline-flex;
        }

        .mat-mdc-outlined-button:not([disabled]),
        .mat-mdc-outlined-button[disabled][disabled] {
          min-width: 44px;
          width: 44px;
          height: 44px;
          border-radius: 50%;
        }
      }

      .mat-mdc-header-row {
        height: 40px;
      }

      .mat-mdc-header-cell {
        background: $theme-app-bar;
      }

      ng-bee-worker-static-data {
        width: calc(100% - 16px);
      }

      ng-bee-worker-static-data.ng-bee-worker-static-data-component
        .worker-chip {
        margin: auto;
        width: calc(100%);
      }

      ng-bee-work-step-static-data {
        width: calc(100% - 16px);
      }

      .workheld-icons.entry-icon {
        width: 35px;
        font-size: 35px;
        height: 35px;
      }

      .mat-mdc-cell.entry-action-icon-wrapper {
        text-align: end;
        max-width: 44px;
      }

      .workheld-icons.entry-action-icon {
        width: 35px;
        font-size: 35px;
        height: 35px;
        color: mat.get-color-from-palette($accent, 500);
        cursor: pointer;
      }

      .paginator.entry-data-paginator {
        margin-top: auto;
      }
    }
  }
}
