// APP

@mixin checklist-instance-data-table($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);

  .checklist-instance-data-table {
    ng-bee-worker-static-data {
      width: 97.5%;
    }

    .checklist-instance-data-table-container {
      .data-table-toolbar {
        height: 64px;
        padding: 0px 16px;

        .right-side-wrapper {
          display: inline-flex;
        }
      }

      .mat-mdc-header-cell.date-header {
        max-width: 128px;
      }

      .mat-mdc-cell.date-cell {
        max-width: 128px;
      }

      .mat-mdc-header-cell.status-header {
        max-width: 128px;
      }

      .mat-mdc-cell.status-cell {
        max-width: 128px;
      }

      // TODO improve
      .mat-mdc-header-cell.select-cell {
        max-width: 44px;
      }

      .mat-mdc-cell.select-cell {
        max-width: 44px;
      }

      .mat-mdc-header-cell.checklistinstance-action-icon-wrapper {
        max-width: 48px;
      }

      .mat-mdc-cell.checklistinstance-action-icon-wrapper {
        max-width: 48px;
      }

      .workheld-icons.checklist-instance-icon {
        width: 35px;
        font-size: 35px;
        height: 35px;
      }

      .workheld-icons.checklistinstance-action-icon {
        width: 35px;
        font-size: 35px;
        height: 35px;
        color: mat.get-color-from-palette($accent, 500);
        cursor: pointer;
        margin-left: auto;
      }
    }
  }
}
