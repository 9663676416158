// APP

@mixin equipment-crud-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);
  .equipment-crud {
    padding: 0px;
    max-height: calc(100vh - 64px);
    overflow: hidden;
  }
}
