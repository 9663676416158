// APP

@mixin worker-time-report-header-card-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);

  $prefix: "worker-dropdown";

  .worker-dropdown-btn {
    .mat-button-wrapper {
      margin: auto;
    }
  }

  .worker-time-report-headder-card {
    padding: 0px;
    margin-bottom: 20px;

    .export-button-span {
      .w-h-file-export-button-wrapper {
        padding: 0px $padding__page;
        .w-h-file-export-timereport {
          text-align: center;
          .w-h-export-button {
            padding: 0px $padding__page;
            border-radius: 4px;
            margin: auto;
            .workheld-icons.export-icon {
              vertical-align: middle;
              width: 34px;
              height: 24px;
              font-size: 24px;
            }
            .timereport-label {
              padding: 4px;
            }
            .hide-text {
              @media (max-width: 980px) {
                display: none;
              }
            }
            mat-spinner {
              display: inline-block;
            }
          }
        }
      }
    }

    .left-side-wrapper {
      height: 100%;

      .time-span-control-container {
        border-radius: 25px;
        height: 100%;
        width: 100%;

        .workheld-icons.time-span-control-icon {
          color: mat.get-color-from-palette($accent);
        }

        .week-before-button {
          border-radius: 25px 0px 0px 25px;
          background-color: #fff;
          width: 16%;
          padding: 0px;
        }

        .week-before-button:hover {
          background-color: mat.get-color-from-palette($accent, 500);
          color: mat.get-color-from-palette($accent, default-contrast);
          .workheld-icons.time-span-control-icon {
            color: mat.get-color-from-palette($accent, default-contrast);
          }
        }

        .mat-flat-button[disabled][disabled] {
          color: #000;
          background-color: #fff;
          line-height: 42px;
        }

        .mat-flat-button[disabled][disabled]:hover {
          color: #000;
          background-color: rgba(0, 0, 0, 0.12);
        }

        .week-container {
          width: 68%;
          border-radius: 0px;
          // color: #000;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          line-height: 44px;
          // vertical-align: middle;
          cursor: default;
          padding: 0px;
        }

        .week-container.back-to-current-date.mat-flat-button:hover {
          background-color: mat.get-color-from-palette($accent, 500);
          color: mat.get-color-from-palette($accent, default-contrast);
          cursor: pointer;
        }

        .week-after-button {
          padding: 0px;
          border-radius: 0px 25px 25px 0px;
          background-color: #fff;
          width: 16%;

          .workheld-icons.time-span-control-icon {
            font-size: 24px;
            width: 24px;
            height: 24px;
            color: mat.get-color-from-palette($accent, 500);
          }
        }

        .week-after-button:hover {
          background-color: mat.get-color-from-palette($accent, 500);
          color: mat.get-color-from-palette($accent, default-contrast);
          .workheld-icons.time-span-control-icon {
            color: mat.get-color-from-palette($accent, default-contrast);
          }
        }

        .mat-flat-button {
          height: 100%;
          line-height: 44px;
        }
      }
    }

    .right-side-wrapper {
      .w-h-worker-time-report-dropdown {
        max-width: 26.3%;
        margin-left: auto;
      }

      @media (max-width: 980px) {
        max-width: 130px;
      }

      .worker-wrapper.selector {
        padding: 34px 0px;
      }
      .worker-wrapper {
        align-self: center;
        // cursor: pointer;
        min-width: calc(100% - 64px);
        max-width: calc(100% - 64px);

        .workheld-icons.worker-placeholder-icon {
          color: mat.get-color-from-palette($accent);
          vertical-align: bottom;
        }

        .select-worker-message {
          padding: 0 $padding__page;
        }

        .worker-detail-span {
          padding: 0 $padding__page;
          max-width: calc(100% - 43px);
        }

        ng-bee-login-static-data.time-report-login-static-data {
          .workheld-icons.time-span-control-icon.display-worker-icon {
            color: mat.get-color-from-palette($primary);
            vertical-align: bottom;
            font-size: 20px;
            height: 20px;
            width: 24px;
          }
        }

        .workheld-icons.display-worker-icon {
          color: mat.get-color-from-palette($primary);
          vertical-align: bottom;
          font-size: 20px;
          height: 20px;
          width: 24px;
        }
      }

      .#{$prefix} {
        &-container {
          position: relative;
          display: flex;
          align-items: center;
        }

        &-btn {
          display: flex;
          justify-content: center;
          color: mat.get-color-from-palette($accent);
          font-size: 28px;
          height: 93px;
          max-width: 64px;
          min-width: 64px;
          margin-left: auto;
          border-radius: 0px;

          .material-icons.expand_more {
            font-size: 34px;
            line-height: 34px;
            height: 34px;
            width: 34px;
            color: mat.get-color-from-palette($accent);
          }
        }
      }
      .dropdown {
        background: white;
        position: absolute;
        top: 92px;
        right: 0px;
        min-width: 33%;
        z-index: 1000;
        transform: translateY(0) scale(0);
        transform-origin: top;
        visibility: hidden;
        transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
          visibility 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);

        @media screen and (max-width: 599px) {
          min-width: 50vw;
          right: 5px;
          transform: translateY(0);
          visibility: hidden;
          transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
            visibility 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
        }

        &.open {
          transform: translateY(0) scale(1);
          visibility: visible;
          max-width: 25%;
        }
        .card {
          .header {
            background: #eeeeee;
            min-height: 54px;
            padding-left: 4px;
            padding-right: 8px;
            padding-top: 8px;
            color: #555;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            align-content: center;
            border-bottom: 1px solid #e0e0e0;

            .search-term {
              width: 100%;
            }

            .extra {
              font-size: 12px;
              color: #888;
            }
          }
        }
        .content {
          overflow: hidden;
          max-height: 55vh;

          .worker {
            min-height: 64px;
            padding: 0 16px 0 14px;
            position: relative;
            // color: #666;
            cursor: pointer;

            .avatar-wrapper {
              text-align: center;
            }

            .icon {
              height: 28px;
              width: 28px;
              line-height: 28px;
              font-size: 18px;
              text-align: center;
              border-radius: 50%;
              background: #fff;
              color: #888;
              border: 1px solid #eee;
              margin: auto 13px auto auto;
            }

            .status {
              text-align: center;
              font-size: 8px;
            }

            .title {
              margin: 0px 0px 0px 15px;
              font-weight: 500;
              font-size: 14px;
            }

            .number-ext {
              font-size: 12px;
            }

            .close {
              font-size: 18px;
              width: 18px;
              height: 18px;
              line-height: 18px;
            }

            &.primary {
              .icon {
                background: #ccc;
                color: #ddd;
              }
            }

            &.accent {
              .icon {
                background: #aaa;
                color: #bbb;
              }
            }

            &.warn {
              .icon {
                background: #eee;
                color: #ddd;
              }
            }

            &.read {
              color: #999;

              .name {
                font-weight: normal;
              }
            }
          }
        }
        .worker.selected {
          background-color: mat.get-color-from-palette($primary, 200);
          color: #fff;
        }

        .footer {
          min-height: 42px;
          border-top: 1px solid #eee;

          .action {
            cursor: pointer;
            color: #aaa;
            text-align: center;
            font-size: 13px;
          }
        }

        .divider {
          width: calc(100% - 30px);
          height: 1px;
          background: #eee;
          margin: 0 16px 0 14px;
        }

        .no-data-message {
          padding: $padding__page;
        }
      }
    }
  }
}

@mixin worker-time-report-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);
  @include worker-time-report-header-card-mixin($theme);

  .worker-drop-down-container {
    cursor: pointer;
    padding-left: 16px;
  }
  .worker-drop-down-container:hover {
    background-color: #f4f4f4;
  }

  .time-report-message {
    min-height: calc(100vh - 400px);
    padding: $padding__page;
    text-align: center;
  }
  .time-report-message.no-data-message {
    min-height: calc(100vh - 400px);
  }

  .mat-option.worker-option {
    font-size: inherit;

    height: 100%;
    .worker {
      min-height: 64px;
      position: relative;
      color: #666;
      cursor: pointer;
      .avatar-wrapper {
        line-height: 1;
        text-align: center;
      }

      .icon {
        height: 40px;
        width: 40px;
        text-align: center;
        border-radius: 50%;
        color: #888;
        margin: auto;
        line-height: 1.1;
      }

      .status {
        text-align: center;
        font-size: 8px;
        line-height: 1.5;
      }

      .title {
        margin: 0px 0px 0px 15px;
        .name {
          font-weight: 500;
          font-size: 14px;
          line-height: 1.5;
        }
        .number-ext {
          font-size: 12px;
          line-height: 1;
        }
      }

      .close {
        font-size: 18px;
        width: 18px;
        height: 18px;
        line-height: 18px;
        line-height: 1;
      }

      &.primary {
        .icon {
          background: #ccc;
          color: #ddd;
        }
      }

      &.accent {
        .icon {
          background: #aaa;
          color: #bbb;
        }
      }

      &.warn {
        .icon {
          background: #eee;
          color: #ddd;
        }
      }

      &.read {
        color: #999;

        .name {
          font-weight: normal;
        }
      }
    }
  }

  // TIME REPORT START

  .worker-time-report {
    padding: $padding__page;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    min-height: calc(100vh - 148px);

    .worker-time-report-container {
      .time-icon {
        padding: 8px;
        font-size: 20px;
      }

      .light-gray {
        background-color: #f4f4f4;
      }

      .gray {
        background-color: #e5e5e5;
      }

      .day-header-wrapper {
        text-align: center;
        .day-header {
          font-weight: bold;
          color: mat.get-color-from-palette($primary, 500);
        }
      }
      .date-header-wrapper {
        padding: 4px 0px;
        font-size: 12px;
        text-align: center;
      }
      .icon-header-day {
        padding: 0px 8px;
        text-align: center;
        color: mat.get-color-from-palette($primary, 500);
      }

      // TIME REPORT BODY CARD - basic for work step
      .time-span-wrapper {
        height: 100%;
        min-height: 100%;
        border-right: dashed 2px #aaa;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .primary-background {
        background-color: mat.get-color-from-palette($primary, 500);
        color: #fff;
      }

      // WORK OBJECT - line
      .time-span-wrapper-primary:empty:before {
        content: "\200b";
        line-height: 34px;
      }
      .time-span-wrapper-primary.time-span-wrapper-last-index {
        height: 100%;
        min-height: 100%;
        border-right: solid 2px mat.get-color-from-palette($primary, 500);
      }

      // WORK ORDER - line
      .time-span-wrapper:empty:before {
        content: "\200b";
        line-height: 28px;
      }
      .time-span-wrapper-work-order:empty:before {
        content: "\200b";
        line-height: 28px;
      }

      .time-span-wrapper.time-span-wrapper-last-index {
        height: 100%;
        min-height: 100%;
        border-right: solid 2px mat.get-color-from-palette($primary, 500);
      }

      .sum-span-wrapper {
        height: 100%;
        min-height: 100%;
      }

      .time-span-wrapper-primary {
        background-color: mat.get-color-from-palette($primary, 500);
        border-right: dashed 2px #aaa;
        height: 100%;
        min-height: 100%;
      }

      .icon-header-sum {
        color: mat.get-color-from-palette($primary, 500);
        text-align: center;
        padding: 0px 8px;
      }

      .sums-label-cell {
        margin-left: 32px;
        line-height: 72px;
        font-weight: bold;
      }

      .day-sums-wrapper {
        border-top: solid 3px mat.get-color-from-palette($primary, 500);
      }

      .worker-time-report-body-card {
        padding: 16px 0px;
        .worker-time-report-body-header {
          .right-sum-span {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            .right-sum-wrapper {
              text-align: center;
              font-weight: bold;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              line-height: 40px;
            }
          }
        }

        // TIME REPORT BODY CARD
        .worker-time-report-body {
          min-height: calc(100vh - 374px);
          .work-object-name-span {
            // z-index: 1;
          }
          .work-object-name {
            padding: 5px 0px;
            margin: 0px 0px 0px 8px;
            max-width: 300px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            .work-object-icon {
              font-size: 18px;
            }
          }
          .unassigned-span {
            z-index: 1;
          }
          .unassigned-label {
            padding: 5px 0px;
            margin: 0px 0px 0px 8px;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .work-order-name {
            margin: 0px 0px 0px 16px;
            height: 100%;
            line-height: 28px;
            overflow: hidden;
            align-self: center;
            white-space: nowrap;
            text-overflow: ellipsis;
            .work-order-icon {
              font-size: 16px;
              height: 24px;
              width: 24px;
              color: mat.get-color-from-palette($primary, 500);
            }
          }
          .work-step-line {
            .work-step-name {
              padding: 4px;
              margin: 0px 0px 0px 32px;
              background-color: #f4f4f4;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;

              .work-step-icon {
                font-size: 14px;
                color: mat.get-color-from-palette($primary, 500);
              }
            }
          }
        }

        .duration-value-ghost:empty:before {
          content: "\200b";
          line-height: 16px;
        }

        .duration-value-work-order-ghost:empty:before {
          content: "\200b";
          line-height: 28px;
        }

        .duration-wrapper-no-color {
          padding: 0px 8px 0px 8px;
        }

        .duration-wrapper-total-sums {
          padding: 0px 8px 0px 8px;
          font-weight: bold;
          text-align: center;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .duration-wrapper {
          padding: 0px 8px 0px 8px;
          font-weight: bold;
          text-align: center;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          background-color: #f4f4f4;
        }
        .duration-cell {
          height: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .duration-value {
          font-size: 14px;
          padding: 4px 0px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .duration-cell-sum {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

// WORKHELD
