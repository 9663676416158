@mixin shift-calendar-form-mixin($theme) {
    $primary: map-get($theme, color, primary);
    $accent: map-get($theme, color, accent);
    $warn: map-get($theme, color, warn);

    .shift-calendar-form {
        height: 100%;
    }

    .shift-calendar-form-card {
        margin: $padding__page;
        min-height: 95%;
    }

    .shift-detail-card-header-flex {
        align-items: center;
        display: flex;
    }

    .shift-calendar-detail-card-body {
        padding: $padding__page;

        .title {
            color: mat.get-color-from-palette($primary, 500);;
            font-weight: 600;
        }

        .mat-column-button {
            justify-content: flex-end;
            padding-right: 16px !important;
        }
    }

    .top-height {
        height: 44px;
    }

    mat-icon.header-icon {
        color: white;
    }

}