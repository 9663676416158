// APP

@mixin tool-create-edit-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);
  .tool-create-edit-card {
    border-radius: 0px;
    padding: 0px;
    margin: $padding__page;

    .tool-create-edit-card-body {
      padding: $padding__page;
      .image-wrapper-span {
        padding: 0px $padding__page 0px 0px;
        .image-wrapper {
          min-width: 270px;
        }
      }

      .tool-crud-button {
        margin-left: 16px;
      }
    }

  }
}
