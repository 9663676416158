@mixin qualification-create-edit-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);
  .qualification-card {
    margin: $padding__page;
    height: 95%;
  }

  .form-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    flex-wrap: wrap;
  }

  .subtitle {
    font-size: 12px;
  }

  .skills-wrapper {
    background-color: #f4f4f4;
    border: 1px solid #e8e8e8;
    border-radius: 8px;
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 200px;
    padding: 12px;

    .assign-skill-wrapper {
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
    }

    .assign-skill-btn {
        margin-top: 8px;
    }

    .assign-skills-autocomplete {
        flex: 0;
        height: 0;
        transition: 0.4s;
        &.expanded {
            height: max-content;
            flex: 1;
        }
        mat-form-field {
            width: 100%;
            min-height: 82px;
        }
    }
  }
}
