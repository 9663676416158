// APP

@mixin work-object-template-data-table-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);
  .work-object-template-data-table {
    margin: $padding__page;
    .work-object-template-data-table-container {

      .data-table-toolbar {
        height: 64px;
        .left-side-wrapper {
          height: 100%;
        }
        .right-side-wrapper {
          text-align: end;
        }
      }

      table {
        width: 100%;
      }
    }
  }
}
