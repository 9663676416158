// APP

@mixin equipment-data-table-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);

  ng-bee-customer-autocomplete.equipment-data-table-customer-autocomplete {
    // margin-left: 16px;
    .dropdown {
      max-width: 23.8%;
      min-width: 23.8%;
      top: 132px;
    }
  }

  .equipment-data-table {
    padding: $padding__page;
    .equipment-data-table-container {
      // margin-bottom: $padding__page;
      .data-table-toolbar {
        height: 64px;
        padding-right: $padding__page;
        .left-side-wrapper {
          height: 100%;
        }
        .right-side-wrapper {
          text-align: end;
          align-self: center;
        }
      }

      table {
        width: 100%;
      }

      .mat-mdc-header-cell.operating-state-header-cell {
        max-width: 128px;
      }

      .mat-mdc-cell.operating-state-cell {
        max-width: 128px;
        .state-dot {
          margin: auto;
        }
      }
    }
  }
}
