// APP

@mixin w-h-location-map-util-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);

  .location-map {
    agm-map {
      height: 270px;
    }
  }
}
