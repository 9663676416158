// APP

@mixin customer-detail-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);
  .customer-detail-card {
    padding: 0px;
    border-radius: 0px;
    margin: $padding__page;

    .customer-detail-card-body {
      padding: $padding__page;
      min-height: calc(100vh - 276px);
    }
  }
}
