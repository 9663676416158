// APP

@mixin skills-data-table-mixin($theme) {
    $primary: map-get($theme, color, primary);
    $accent: map-get($theme, color, accent);
    $warn: map-get($theme, color, warn);
    
    .skills-data-table {
      padding: $padding__page;
      .skills-data-table-container {
        // margin-bottom: $padding__page;
        .data-table-toolbar {
          height: 64px;
          padding-right: 8px;
          .left-side-wrapper {
            height: 100%;
          }
          .right-side-wrapper {
            text-align: end;
            align-self: center;
          }
        }
  
        

        table {
          width: 100%;
        }

        .mdc-button__label {
          color: #fff
        }      
  
        .skills-icon {
          color: #fff;
        }
  
        .mat-mdc-row:hover {
          .material-icon {
            color: #fff;
          }
        }

        .skill-extid {
          display: flex;
          align-items: center;
        }

        .skill-icon {
          margin-left: 5px;
          margin-right: 5px;
          color: mat.get-color-from-palette($primary, 500);;
          overflow: visible;
        }
      }
    }
    .table-filter-padding {
      padding-left: 10px;
    }
  }
  