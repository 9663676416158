$partner-scheduler-color: #e0e7ec !default;

$project-background-color: #95c193 !default;
$project-nested-background-color: #25882a !default;
$project-border-color: $project-background-color !default;
$project-color: #ffffff !default;
$milestone-project-background-color: $project-background-color !default;

$project-stopped-background-color: #e4e4e4 !default;
$project-stopped-nested-background-color: #d3d3d3 !default;
$project-stopped-border-color: $project-stopped-background-color !default;
$project-stopped-color: #000000 !default;
$milestone-project-stopped-background-color: $project-stopped-background-color !default;

$work-order-background-color: #b9c3d4 !default;
$work-order-nested-background-color: #868f9b !default;
$work-order-border-color: $work-order-background-color !default;
$work-order-color: #ffffff !default;
$milestone-work-order-background-color: $work-order-background-color !default;

$case-background-color: #eba09e !default;
$case-nested-background-color: #ef5350 !default;
$case-border-color: $case-background-color !default;
$case-color: #ffffff !default;
$milestone-case-background-color: $case-background-color !default;

$standing-order-background-color-1: #cccccc !default;
$standing-order-background-color-2: #ffffff !default;
$standing-order-border-color: #000000 !default;
$standing-order-color: #000000 !default;
$milestone-standing-order-background-color: $standing-order-border-color !default;

.b-grid-header .b-sch-timerange {
  &.unplanned {
    background-color: var(--warn-color-500);
    color: #fff;
  }
}

.b-timeline-subgrid .b-sch-range {
  // colors for equipment planner timeranges
  &.planned {
    background-color: #a1d2fa77;
  }

  &.unplanned {
    background-color: var(--warn-color-100);
    opacity: 0.5;
  }
}

// colors for shift planner timeranges
.b-grid-header .b-sch-timerange.b-sch-line {
  &.start-calendar {
    background-color: #ec9f07;
  }

  &.end-calendar {
    background-color: var(--warn-color-500);
  }
}

.b-timeline-subgrid .b-sch-line {
  &.start-calendar {
    border-left: 2px dashed #ec9f07;
  }

  &.end-calendar {
    border-left: 2px dashed var(--warn-color-500);
  }
}

.b-container .b-has-label.b-open .b-label,
.b-container .b-has-label:focus-within .b-label {
  color: var(--primary-color-500) !important;
}

.b-widget.b-datetimefield .b-field-inner::before,
.b-widget.b-numberfield .b-field-inner::before,
.b-widget.b-textfield .b-field-inner::before {
  background-color: var(--primary-color-500) !important;
}

.wh-absence-viewer {
  --datepicker-selected-cell-background-color: #fff !important;

  .b-calendar-cell.b-selected-date:not(.b-in-range) > .b-datepicker-cell-inner {
    opacity: 1 !important;
    color: black !important;
  }

  .wh-calendar-indicator {
    width: 10px;
    height: 10px;
    border-radius: 16px;

    &.wh-absence {
      background-color: #3e4e5f;
    }

    &.wh-assigned-already {
      background-color: #b11f00;
    }
  }
}

.wh-legend {
  .legend-indicators {
    span {
      width: 10px;
      height: 10px;
      border-radius: 16px;
      &.wh-absence {
        background-color: #3e4e5f;
      }

      &.wh-assigned-already {
        background-color: #b11f00;
      }
    }
  }
}

.b-float-root:has(.wh-summary-popup) {
  background-color: rgba(0, 0, 0, 0.274) !important;
}

.b-blue {
  background-color: var(--accent-color-500) !important;
}

.b-button:not(.b-blue):not(.b-red):not(.b-tab) {
  background-color: var(--accent-color-200) !important;
  color: var(--accent-color-500) !important;
}

.b-tabpanel-tab.b-active {
  border-color: var(--primary-color-500) !important;
  label {
    color: var(--primary-color-500) !important;
  }
}
