@mixin flex($direction: row, $align: center, $justify: unset, $wrap: nowrap) {
  display: flex;
  flex-direction: $direction;
  flex-wrap: $wrap;
  align-items: $align;
  justify-content: $justify;
}

.flex--r-c {
  @include flex(row, center, flex-start);
}

.flex--r-fs-c {
  @include flex(row, flex-start, center);
}
.flex--r-fs {
  @include flex(row, flex-start, flex-start);
}

.flex--c-fs {
  @include flex(column, flex-start, flex-start);
}

.flex--r-c-r {
  @include flex(row, center, flex-end);
}

.flex--r-c-c {
  @include flex(row, center, center);
}

.flex--c-c-c {
  @include flex(column, center, center);
}

.flex--c-u-c {
  @include flex(column, unset, center);
}

.flex--c-c-fs {
  @include flex(column, center, flex-start);
}

.flex--c-c-fe {
  @include flex(column, center, flex-end);
}

.flex--c-c-r {
  @include flex(column, flex-end, center);
}
.flex--c-c-l {
  @include flex(column, flex-start, center);
}

.flex--r-c-sb {
  @include flex(row, center, space-between);
}

.flex--c-c-sb {
  @include flex(column, center, space-between);
}

.flex--c-fs-sb {
  @include flex(column, flex-start, space-between);
}

.flex--r-c-se {
  @include flex(row, center, space-evenly);
}

.flex--r-fe-fe {
  @include flex(row, flex-end, flex-end);
}

.flex--r-c-l {
    @include flex(row, flex-start, center);
}

.flex--r-s-sb {
  @include flex(row, flex-start, space-between);
}

.flex--r-e-sb {
  @include flex(row, flex-end, space-between);
}

.flex--r-c-fe {
  @include flex(row, center, flex-end);
}

.flex-gap--col {
  column-gap: 16px;
}
.flex-gap--row {
  row-gap: 16px;
}
.flex-gap {
  gap: 16px;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.flex-4 {
  flex: 4;
}

.flex-5 {
  flex: 5;
}
.flex-6 {
  flex: 6;
}
.flex--col {
  display: flex;
  flex-direction: column;
}

.flex--row {
  display: flex;
  align-items: center;
}

.flex--grow {
  flex-grow: 1;
}

.flex--wrap {
  flex-wrap: wrap;
}

.align-self-center {
  align-self: center;
}

$sizes: (
  2: 2,
  4: 4,
  8: 8,
  12: 12,
  16: 16,
  24: 24,
  28: 28,
  32: 32,
);

@each $space-size, $size in $sizes {
  .gap--#{$space-size} {
    gap: #{$size}px;
  }
}
