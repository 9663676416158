// APP

@mixin w-h-dnd-assignment-chip-list-util-mixin($theme) {
  .mdc-evolution-chip__action--presentational {
    cursor: unset !important;
  }
  .mat-mdc-standard-chip .mdc-evolution-chip__cell--primary,
  .mat-mdc-standard-chip .mdc-evolution-chip__action--primary,
  .mat-mdc-standard-chip .mat-mdc-chip-action-label {
    overflow: hidden !important;
  }
  .disabled-cursor {
    cursor: not-allowed;
  }

  .grab-cursor {
    cursor: grab;
    cursor: -webkit-grab;
  }

  .truncate {
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .mdc-evolution-chip-set__chips {
    width: 100%;
  }
}

:host::ng-deep {
  .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
    right: 12px;
    top: 16px;
    z-index: 10;
    margin-left: -28px;
  }
  .mat-badge-content {
    background-color: var(--accent-color-500);
  }

  .mat-mdc-chip-action,
  .mdc-evolution-chip__cell {
    pointer-events: none;
  }
}

.mat-chip-list-stacked-wrapper {
  max-height: calc(100vh - 250px);
  overflow-y: auto;
}

.assignments-wrapper {
  padding: 8px 16px;
  display: flex;
  gap: 8px;
  box-sizing: border-box;
  align-items: center;
  overflow-y: hidden;
  height: 100%;

  .actions {
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 2;
    padding-top: 8px;
    padding-bottom: 4px;
  }
}
