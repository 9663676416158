// APP

@mixin work-order-create-edit-form-mixin($theme) {
    $primary: map-get($theme, color, primary);
    $accent: map-get($theme, color, accent);
    $warn: map-get($theme, color, warn);

    .work-order-create-edit-form {
        .map-form-wrapper {
            padding: 0 $padding__page;
        }

        .work-order-create-edit-card-body {
            padding: 0px $padding__page $padding__page $padding__page;
            .row-wrapper.detail-wrapper {
                margin-bottom: 0;

                .detail-span-left {
                    padding-right: $padding__page;
                }
            }
        }

        .detail-actions-wrapper.work-order-actions {
            text-align: right;
            padding: $padding__page;

            .action-button {
                margin-left: $padding__page;
            }
        }
    }

    .assignment-span {
        background-color: #fff;
        .mat-tab-body {
            overflow: hidden;
            height: calc(100vh - 162px);
            overflow-x: hidden;
            .mat-tab-body-content {
                height: 100%;
                overflow: hidden;
                // padding: 16px 24px 16px 16px;
            }
        }
        .mat-tab-label {
            height: 48px;
            padding: 0px;
            cursor: pointer;
            box-sizing: border-box;
            opacity: 1;
            min-width: 0px;
            text-align: center;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            white-space: nowrap;
            position: relative;
            background-color: #fff;
            color: mat.get-color-from-palette($primary, 400);
        }
    }
}
