// APP

@mixin w-h-file-image-util-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);
  .w-h-file-image-util {
    .w-h-file-image {
      min-width: 270px;
      text-align: center;
      img.normal {
        max-height: 270px;
        max-width: 270px;
        height: auto;
        width: 100%;
        @include mat.elevation(2);
      }
    }
    .w-h-file-image-actions {
      margin-top: -24px;
      text-align: end;
      max-width: 92.5%;
      .mat-fab.w-h-file-button {
        min-width: 44px;
        width: 44px;
        height: 44px;
        margin-left: 8px;
        float:right;
        .mat-button-wrapper {
          padding: 6px 0;
        }
      }
      .mat-fab.w-h-file-button.w-h-file-delete {
        background-color: #fff;
        color: mat.get-color-from-palette($accent);
        border: 2px solid mat.get-color-from-palette($accent);
        float: unset;
        .mat-button-wrapper {
          // padding: 10px 0;
          vertical-align: unset;
        }
      }
    }

    .w-h-image-type-error{
      color: mat.get-color-from-palette($warn);
      font-size: 12px;
      margin-top: 5px;
      text-align: end;
      max-width: 270px;

    }
  }
}
