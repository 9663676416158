// APP

@mixin work-object-status-column-mixin($theme) {
  .work-object-status-cell {
    padding: 12px;
  }
}

.loading {
  position: absolute;
  top: 0;
  margin-top: 32px;
  width: 100%;
}
