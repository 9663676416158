@mixin mat-dialog-update-absencerequest-component-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);

}

.absence-request-form {
    width: 600px;
    max-width: 100%;
  }