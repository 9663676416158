// APP

@mixin mat-dialog-confirm-entry-component-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);
  p {
    line-height: 1.5;
  }

  .highlight {
    font-weight: bold;
    background: #f4f4f4;
    padding: 2px 4px;
    border-radius: 4px;
  }
}

mat-dialog-content {
  padding: 16px !important;
}

mat-dialog-actions {
  padding: 16px;
  padding-top: 0px;
}
