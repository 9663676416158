// APP

@mixin w-h-status-overview-search-util-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);
}

.custom-filter {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 4px;
}
