// APP

@mixin w-h-work-step-assignment-chip-mixin($theme) {
    
    mat-chip.work-step-assignment-chip {
        width: 100%;
    }
    
    .disabled-cursor {
        cursor: not-allowed;
    }
}
