@import "/src/styles/bryntum_colors";

@mixin w-h-team-planner-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);

  .sub-items {
    align-items: baseline;
    .event-icon.status {
      line-height: 1;
      font-size: 12px;
      margin: 0;
    }
  }
  // @debug "Show color: #{mat.get-color-from-palette($primary)}";

  // region PAGE STYLES
  .page-card.w-h-team-planner {
    height: calc(100vh - 128px);
    margin-top: 16px;
    border-radius: 0;
    overflow: hidden;
    padding: 0;

    // Wrapper for top bar
    .w-h-team-planner-header {
      height: 70px;

      // Top bar
      ng-bee-w-h-time-span-header-util {
        .w-h-time-span-toolbar {
          min-height: 50px;
          height: 50px;

          .search-term-input {
            margin-top: 0;
          }
        }
      }
    }

    // Wrapper for schedulers
    .w-h-team-planner-body {
      overflow: auto;
      height: calc(100vh - 178px);
      display: flex;
      flex-direction: column;
    }
  }
  // endregion

  // region SCHEDULER STYLES
  // Note, event styles and event editor styles are specified individually down below.
  bryntum-scheduler-pro.b-schedulerpro {
    // region style for search function
    &.b-highlighting {
      .b-sch-event {
        opacity: 0.2;

        &.b-match {
          opacity: 1;
          box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        }
      }
    }
    //endregion

    // Partner specific styles
    &.partner-scheduler {
      .b-grid-body-container {
        background-color: $partner-scheduler-color;
        border-top: 7px solid #ebebeb;

        .b-grid-row {
          min-height: 100px;
          border-bottom: none;

          &.b-selected,
          &.b-hover {
            .b-grid-cell {
              background-color: $partner-scheduler-color;
            }
          }

          .b-grid-cell {
            .b-resource-info {
              min-height: 100px;
              white-space: break-spaces;
              display: flex;
              align-items: flex-start;
              margin-top: 4px;
            }
          }
        }
      }
    }
  }
  // endregion

  // region EVENT STYLES
  // Has to be specified as a standalone selector, because
  // when you drag an event, it is rendered outside the scheduler element.
  .b-sch-event {
    // do not add overflow hidden to b-sch-event ever becasue it will mess up
    // sticky titles on horizontal scroll
    .title-item,
    .sub-items {
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    // Normal events
    &:not(.b-milestone) {
      .event-text {
        display: inline-block;
        margin-left: 4px;

        .event-icon {
          font-size: 12px;
        }
      }

      .text-strike-through {
        text-decoration: line-through;
      }

      .text-bold {
        font-weight: bold;
      }

      // region COLORS OF NORMAL EVENTS
      // .b-sch-color-* like CSS classes are generated based on `this.eventColor` values set in
      // `@workheld/workheld-shared-lib/src/lib/models-bryntum/w-h-bry-event.model.tsWHBryEventDOM`
      .b-sch-event-wrap.b-sch-style-plain.b-sch-color-green & {
        background-color: $project-background-color;
        border-color: $project-border-color;
        color: $project-color;
      }

      .b-sch-event-wrap.b-sch-style-plain.b-sch-color-stopped & {
        background-color: $project-stopped-background-color;
        border-color: $project-stopped-border-color;
        color: $project-stopped-color;
      }

      .b-sch-event-wrap.b-sch-style-plain.b-sch-color-workorder & {
        background-color: $work-order-background-color;
        border-color: $work-order-border-color;
        color: $work-order-color;
      }

      .b-sch-event-wrap.b-sch-style-plain.b-sch-color-red & {
        background-color: $case-background-color;
        border-color: $case-border-color;
        color: $case-color;
      }

      .b-sch-event-wrap.b-sch-style-line.b-sch-color-disabled & {
        // Zebra stripes
        background-image: repeating-linear-gradient(
          45deg,
          $standing-order-background-color-1,
          $standing-order-background-color-1 8px,
          $standing-order-background-color-2 8px,
          $standing-order-background-color-2 16px
        );
        border-color: $standing-order-border-color;
        color: $standing-order-color;
      }

      // Override line styles (use complex selector to override Bryntum rules)
      div.b-sch-event-wrap.b-sch-style-line & {
        // "center" => "flex-start"
        justify-content: flex-start;
        // "5px" => "3px"
        border-width: 3px 0 0 0;
        // "5px" => "0px"
        top: 0;
        // "auto !important" => "100% !important"
        height: 100% !important;

        // Override top-left and top-right corners
        &:not(.b-milestone)::before,
        &:not(.b-milestone)::after {
          .b-sch-horizontal & {
            // "5px" => "3px"
            border-inline-start-width: 3px;
            // "-9px" => "2px"
            top: -2px;
            // "13px" => "6px"
            height: 6px;
          }
        }
      }
      // endregion
    }

    // Milestones
    &.b-milestone {
      // Make milestones looks like icons
      .b-sch-event-wrap & {
        .b-sch-event-content {
          justify-content: center;
          background-color: transparent;

          &::before {
            border-width: 0;
          }
        }
      }

      // region COLORS OF MILESTONES
      .b-sch-event-wrap.b-sch-style-plain.b-sch-color-green & {
        color: $milestone-project-background-color;
      }

      .b-sch-event-wrap.b-sch-style-plain.b-sch-color-stopped & {
        color: $milestone-project-stopped-background-color;
      }

      .b-sch-event-wrap.b-sch-style-plain.b-sch-color-workorder & {
        color: $milestone-work-order-background-color;
      }

      .b-sch-event-wrap.b-sch-style-plain.b-sch-color-red & {
        color: $milestone-case-background-color;
      }

      .b-sch-event-wrap.b-sch-style-line.b-sch-color-disabled & {
        background-image: none;
        color: $milestone-standing-order-background-color;
      }
      // endregion
    }
  }
  // endregion

  // region EVENT EDITOR STYLES
  // Has to be specified as a standalone selector, since it is rendered outside the scheduler element.
  .b-eventeditor {
    .b-popup-header {
      background-color: mat.get-color-from-palette($primary);
      color: #fff;
    }
  }
  // endregion

  // TODO: Refactor search feature. See https://bryntum.com/products/scheduler/examples/filtering/ demo
  // SEARCH
  .b-sch-style-plain.b-sch-color-greenfound,
  .b-sch-style-plain.b-sch-color-redfound,
  .b-sch-style-plain.b-sch-color-disabledfound,
  .b-sch-style-plain.b-sch-color-workorderfound,
  .b-sch-style-plain.b-sch-color-stoppedfound {
    opacity: 0.5;
  }
}

// nested events config
$work-object-event-color: rgb(0, 161, 0) !default;
$work-order-event-color: #b5c0ce !default;
$work-order-nested-event-color: #9ea9b8 !default;

:host::ng-deep {
  bryntum-scheduler-pro.b-schedulerpro {
    &.b-highlighting {
      .b-sch-label-top {
        opacity: 1;
      }
      .b-sch-event {
        opacity: 0.2;
        &.b-match {
          opacity: 1;
          box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

          .b-sch-label-top {
            opacity: 0.2;
          }
        }
      }
    }
  }

  .b-sch-event-wrap .b-sch-label-top {
    font-weight: 600;
  }

  .b-sch-label {
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .b-sch-event {
    padding-inline-start: 0;
    border-radius: 0px;
  }
  .bry-project-data-info {
    position: sticky !important;
    height: 100%;
    top: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-right: 8px;

    & > div {
      position: sticky;
      left: 0;
      overflow: hidden;
      span {
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
        width: 100%;
      }
    }
  }

  .eventWithAgenda {
    overflow: visible;
    position: relative;
  }
  .b-sch-event-content {
    overflow: visible;
    width: calc(100% - 10px);
  }
  .eventWithAgenda .b-sch-event-content {
    background-color: inherit;
    .nested {
      position: fixed;
      background-color: inherit;
      font-size: 0.9em;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
      height: 100%;
      &.workorder {
        background-color: $work-order-nested-background-color;
      }
      &.stopped {
        background-color: $project-stopped-nested-background-color;
      }
      &.red {
        background-color: $case-nested-background-color;
      }
      &.green {
        background-color: $project-nested-background-color;
      }
    }
  }

  .sub-items {
    align-items: baseline;
    .event-icon.status {
      line-height: 1;
      font-size: 12px;
      margin: 0;
    }
  }
}
