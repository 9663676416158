@mixin mat-dialog-add-qualification-component-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);

  .label-wrapper {
    margin-bottom: 16px;
  }
  .workspace-icon {
    color: #fff;
  }

  mat-dialog-add-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    width: 100%;
  }
  .confirm-label {
    align-self: center;
    color: mat.get-color-from-palette($primary);
  }

  .mat-dialog-add-content {
    padding: 16px;
  }
  .toggle-renewed {
    display: flex;
    flex-direction: column;
    padding-bottom: 16px;
    .title {
      font-weight: bold;
      color: mat.get-color-from-palette($primary);
    }
  }
}