@mixin mat-dialog-add-skill-component-mixin($theme) {

  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);

  .label-wrapper {
    margin-bottom: 16px;
  }

  .mat-dialog-add-skill-container {
    width: 400px;
    max-width: 100%;
  }

  .confirm-label {
    align-self: center;
  }

}