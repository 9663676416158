// APP

@mixin mat-dialog-use-work-object-template-entry-component-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);

  .use-work-object-template-mat-dialog {
    .mat-mdc-row.selected-template {
      background-color: mat.get-color-from-palette($primary, 200);
      .mat-mdc-cell {
        color: #fff;
      }
    }

    .use-work-object-template-mat-dialog-body {
      padding: 0px;

      .mat-stepper-horizontal.use-template-stepper {
        .mat-horizontal-content-container {
          overflow: hidden;
          padding: 0;
        }
      }

      .select-template-msg {
        text-align: center;
        vertical-align: middle;
        min-height: 559px;
      }

      .mat-selection-list.work-order-selection-list {
        min-height: 404px;
        max-height: 404px;
        overflow-y: auto;
        margin-bottom: $padding__page;
      }

      .dialog-action-wrapper {
        padding: 0 $padding__page $padding__page $padding__page;
      }

      ng-bee-list.use-work-object-template-ng-bee-list .ng-bee-list {
        .mat-mdc-table.data-table {
          height: 490px;
          min-height: 490px;
        }
        margin-bottom: 0px;
        box-shadow: unset;
      }

      .selected-work-object-template {
        padding: 0 $padding__page;
        flex-grow: 1;
      }

      .agm-core-location-form-wrapper {
        padding: 0 $padding__page;
        margin-bottom: 28px;
      }

      .w-h-label.desc-label {
        margin-top: 4px;
        margin-bottom: 0;
      }
    }

    .mat-dialog-actions.dialog-action-wrapper {
      padding: 15px;
      min-height: unset;
    }
    .w-h-default-button.dialog-button {
      max-width: 45%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .mat-accent.dialog-button {
      max-width: 45%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .w-h-dialog-button {
      margin-right: $padding__page;
    }
  }
}
