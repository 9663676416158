@mixin mat-dialog-add-shift-models-component-mixin($theme) {

  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);

  .label-wrapper {
    margin-bottom: 16px;
  }

  .mat-dialog-add-shift-model-container {
    width: 1200px; //width of the dialog when it is empty
    max-width: 100%;
  }

  .shift-input-model-container {
    display: flex;
    flex-direction: row;
    padding-top: 8px;
    padding-bottom: 8px;
    .mat-mdc-form-field {
      min-width: none !important;
      width: 100% !important;
      flex: 1 !important;
    }
  }

  .confirm-label {
    align-self: center;
  }

}