// APP

@mixin mat-dialog-comment-entry-component-mixin($theme) {
    .comment-mat-dialog {
         

        .comment-mat-dialog-header {
            padding: 8px $padding__page;
        }
        .comment-mat-dialog-body {
            padding: 0px $padding__page;
        }

        .workheld-icons.dialog-close-icon {
            cursor: pointer;
        }
    }
}