// WORKHELD

@mixin entry-file-img-gallery-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);

  .entry-file-img-gallery {
    .entry-file-message {
      padding: $padding__page;
    }

    .entry-file-paginator {
      background: $theme-app-bar;

      .mat-paginator-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        min-height: 39px;
        padding: 0 8px;
        flex-wrap: wrap-reverse;
        width: 100%;
      }
    }

    .w-h-label.attachment-label {
      margin-bottom: 0px;
    }

    .thumbnail-list-wrapper {
      min-height: 120px;

      .thumbnail-list-item-wrapper {
        max-width: calc(10% - 8px);
        display: block;
        margin: 4px;
        min-width: 110px;

        .thumbnail-wrapper {
          min-height: 84px;
          background: whitesmoke;
          text-align: center;
          cursor: pointer;
          padding: 4px;

          .thumbnail {
            display: inline-block;
            max-height: 136px;
            max-width: 100%;
          }

          .thumbnail:hover {
            transform: scale(1.05);
          }
        }

        .thumbnail-meta-wrapper {
          background: $theme-app-bar;
          padding: 4px;

          .file-name {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }

          .download-img-icon {
            color: mat.get-color-from-palette($accent);
            vertical-align: middle;
            cursor: pointer;
            font-size: 18px;
            height: 18px;
            width: 20px;
          }
        }
      }
    }
  }
}
