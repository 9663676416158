// APP

@mixin tool-data-table-mixin($theme) {
  .tool-data-table {
    padding: $padding__page;
    .tool-data-table-container {
      // margin-bottom: $padding__page;
      .data-table-toolbar {
        height: 64px;
        padding-right: $padding__page;
        .left-side-wrapper {
          height: 100%;
        }
        .right-side-wrapper {
          text-align: end;
          align-self: center;
        }
      }

      table {
        width: 100%;
      }
    }
  }
}
