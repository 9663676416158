// APP

@mixin entry-process-form-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);

  .entry-process-form {
    .entry-process-form-body {
      display: flex;
      flex-direction: row;
      padding: 0;
      gap: 12px;
      align-items: flex-start;
      justify-content: space-between;

      .entry-status-container {
        background-color: #f4f4f4;
        border-radius: 8px;
        padding: 12px;
        margin-top: 6px;
      }

      .w-h-label.manager-comment-label {
        margin-bottom: 0;
      }
    }
  }
}
