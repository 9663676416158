// APP

@mixin w-h-file-export-button-util-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);
  .w-h-file-export-button-wrapper {
    .w-h-export-button {
      padding: $padding__page;
      border-radius: 16px;
      .workheld-icons.export-icon {
        font-size: 28px;
        line-height: 1.8;
      }
      mat-spinner {
        display: inline-block;
      }
    }
  }

  .export-type-text {
    padding: $padding__page;
    text-align: center;
  }
}
