@mixin shift-group-data-table-mixin($theme) {
    $primary: map-get($theme, color, primary);
    $accent: map-get($theme, color, accent);
    $warn: map-get($theme, color, warn);

    .shift-group-data-table-container {
        padding: $padding__page;

        .shift-group-header {
            padding: $padding__page;
        }

        .shift-group-content {
            align-items: center;
            display: flex;
            flex-direction: column;
            padding-top: $padding__page;
            padding-left: $padding__page;
            padding-right: $padding__page;

            position: relative;
            min-height: 100px;

            .shift-group {
                width: 100%;

            }

            .loading-overlay {
                position: absolute;
                background-color: rgba(0, 0, 0, 0.15);

                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                // background: rgba(0, 0, 0, 0.15);
                z-index: 1;
                display: flex;
                align-items: center;
                justify-content: center;

            }

            .accordion-headers-align {
                width: 100%;

                .mat-expansion-panel {
                    border-radius: 0px;
                }

                .mat-expansion-panel-header-description {
                    justify-content: space-between;
                    align-items: center;

                }
            }
        }
    }
    .expansion-panel-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
    }

    .vertical-container-right {
        background-color: #fff;
        display: flex;

    }

}