//

@mixin mat-dialog-ngx-image-cropper-entry-component-mixin($theme) {

  .mat-dialog-ngx-image-cropper {
     

    .detail-card-header.mat-dialog-ngx-image-header {
      margin-bottom: $padding__page;
    }
    .cropper-container {
      padding: 0px 0px 0px 16px;
      max-height: 55vh;
      .image-cropper-wrapper {
        margin-bottom: 16px;
        image-cropper {
          padding: 0px;
          width: 100%;
          width: -moz-available; /* WebKit-based browsers will ignore this. */
          width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
          max-height: 50vh;
          height: auto;
        }
      }
    }
  
    .mat-dialog-actions.dialog-action-wrapper {
      padding: $padding__page;
      min-height: unset;
    }
  
    .w-h-default-button.dialog-button {
      max-width: 45%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-right: $padding__page;
    }
    .mat-accent.dialog-button {
      max-width: 45%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  
    .picture {
      width: 150px;
      height: 150px;
      border-radius: 0;
      margin: 8px auto;
    }
  }
}
