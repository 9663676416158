// APP

@mixin skills-create-edit-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);

  .skills-create-edit-form {
    height: 100%;
  }
  .skills-create-edit-card {
    margin: $padding__page;
    min-height: 95%;
  }

  .detail-card-header-flex {
    align-items: center;
    display: flex;
  }
  .skills-detail-card-body {
    padding: $padding__page;
    //min-height: calc(100vh - 244px);
  }

  .top-height{
    height: 44px;
  }

  mat-icon.header-icon {
    color: white;
  }

  .form-div {

  }
}
