// APP

@mixin tool-detail-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);
  .tool-detail-card {
    padding: 0px;
    border-radius: 0px;
    margin: $padding__page;

    .tool-detail-span {
      max-width: calc(100% - 284px);
    }

    .tool-detail-card-body {
      padding: $padding__page;
      min-height: calc(100vh - 278px);

      .image-wrapper-span {
        padding: 0px $padding__page 0px 0px;
        .image-wrapper {
          min-width: 270px;
        }
      }
    }
  }

  .desc-line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 15;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
