// APP

@mixin work-step-master-data-table-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);

  .work-step-master-data-table {
    ng-bee-worker-static-data {
      vertical-align: bottom;
    }

    ng-bee-tool-static-data {
      vertical-align: bottom;
    }

    // padding: $padding__page;
    .work-step-master-data-table-container {
      ng-bee-list.work-step-master-data-ng-bee-list .ng-bee-list {
        .mat-mdc-header-row {
          min-height: 40px;
        }
      }

      mat-cell.first-cell:first-of-type,
      mat-footer-cell.first-cell:first-of-type,
      mat-header-cell.first-cell:first-of-type {
        padding-left: 0px;
        width: 70px;
      }

      .workheld-icons.dnd-icon {
        cursor: move;
      }

      .w-h-icon-button.disabled {
        color: rgba(0, 0, 0, 0.26);
        cursor: not-allowed;
      }

      .assignment-count {
        color: mat.get-color-from-palette($accent);
        border: solid 1px mat.get-color-from-palette($accent);
      }

      .skill-count {
        background-color: #e0e0e0;
        border: solid 2px mat.get-color-from-palette($accent);
        min-width: 24px;
        min-height: 24px;
        border-radius: 50%;
        text-align: center;
        line-height: 1.7;
        display: inline;
      }

      .sort-by-menu {
        margin-left: $padding__page;
        min-width: 156px;
      }

      .mat-paginator.paginator {
        display: block;
        background: $theme-app-bar;
        min-height: 52px;

        .mat-paginator-container {
          min-height: 52px;
        }

        .mat-paginator-page-size-select {
          margin: 0px 4px 0 4px;
          width: 56px;
        }
      }

      .image-cell.first-cell {
        min-width: 70px;
        width: 70px;
      }

      .mat-mdc-row:hover {
        .assignment-count {
          color: mat.get-color-from-palette($primary, default-contrast);
          border: solid 1px #fff;
        }
      }

      .dnd-workstep-button {
        color: mat.get-color-from-palette($accent);
        cursor: move;
        width: 24px;
        height: 24px;

        mat-icon {
          font-size: 24px;
        }
      }

      .workheld-icons.work-step-icon {
        font-size: 24px;
      }

      .dnd-assigned-span-header-equipment {
        display: inline-block;
        margin-left: 4px;
        min-width: 40px;
        height: 24px;

        i {
          font-size: 22px;
        }
      }

      .dnd-assigned-span-equipment {
        display: flex;
        align-self: center;
        margin-right: 0px;
        min-width: 40px;
        height: 28px;

        i {
          font-size: 22px !important;
        }
      }

      .workheld-icons.dnd-header-icon {
        margin-left: 1px;
      }

      // SORT POSITION
      .mat-mdc-header-cell.sort-position-header {
        max-width: 42px;
        font-size: 16px;
      }

      .mat-mdc-cell.sort-position-cell {
        max-width: 42px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        .sort-position {
          background-color: mat.get-color-from-palette($accent);
          min-width: 34px;
          min-height: 34px;
          border-radius: 50%;
          color: mat.get-color-from-palette($accent, default-contrast);
          text-align: center;
          line-height: 2;
          font-size: 18px;
          font-weight: 600;
        }
      }

      // MODEL
      .mat-mdc-header-cell.model-header {
        max-width: 192px;
      }

      .mat-mdc-cell.model-cell {
        max-width: 192px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .mat-mdc-header-cell.single-icon-header {
        max-width: 40px;
        justify-content: center;
        align-items: center;
      }

      .mat-mdc-cell.single-icon-cell {
        max-width: 40px;
        justify-content: center;
        align-items: center;

        .active {
          color: var(--accent-color-500);
          cursor: pointer;
        }
      }

      .mat-mdc-header-cell.material-attached-header {
        max-width: 40px;
      }

      .mat-mdc-cell.material-attached-cell {
        max-width: 40px;
      }

      .mat-mdc-header-cell.model-header.model-header-expanded {
        max-width: unset;
      }

      .mat-mdc-cell.model-cell.model-cell-expanded {
        max-width: unset;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .mat-mdc-header-cell.date-header {
        max-width: 128px;
      }

      .mat-mdc-cell.date-cell {
        max-width: 128px;
      }

      // STATUS
      .mat-mdc-header-cell.status-header {
        max-width: 112px;
      }

      .mat-mdc-cell.resolution_status,
      .mat-mdc-header-cell.resolution_status {
        max-width: 130px;
      }

      .mat-mdc-cell.planned_work,
      .mat-mdc-header-cell.planned_work {
        max-width: 120px;
      }

      .mat-mdc-cell.status-cell {
        max-width: 112px;
      }

      // EDIT
      .mat-mdc-header-cell.edit-header {
        max-width: 56px;
      }

      .mat-mdc-cell.edit-cell {
        max-width: 56px;
      }

      .work-step-edit-button {
        // margin-left: 8px;
        text-overflow: ellipsis;
        overflow: hidden;
        padding: 0 4px;
        min-width: 40px;
      }

      ng-bee-w-h-dnd-placeholder-util {
        width: 100%;
      }

      .dnd-assigned {
        width: 100%;
        // height: 44px;
        display: flex;
        border-radius: 0px;
        border: dashed 1px transparent;
      }

      .dnd-assigned.w-h-dnd-placeholder-util {
        width: 100%;
        max-height: 44px;
        border: 1px mat.get-color-from-palette($primary, 300) dashed;
        border-radius: 0px;
        background-color: mat.get-color-from-palette($primary, 50);
      }
    }
  }

  .mat-mdc-row.cdk-drag-preview {
    background-color: #fff;

    ng-bee-list.work-step-master-data-ng-bee-list .ng-bee-list {
      .mat-mdc-header-row {
        min-height: 40px;
      }
    }

    ng-bee-worker-static-data {
      vertical-align: bottom;
    }

    ng-bee-tool-static-data {
      vertical-align: bottom;
    }

    mat-cell {
      position: unset !important;
    }

    mat-cell.first-cell {
      padding-left: 0px;
      width: 70px;
      max-width: 70px;
    }

    .assignment-count {
      color: mat.get-color-from-palette($accent);
      border: solid 1px mat.get-color-from-palette($accent);
    }

    .mat-paginator.paginator {
      display: block;
      background: $theme-app-bar;
      min-height: 52px;

      .mat-paginator-container {
        min-height: 52px;
      }

      .mat-paginator-page-size-select {
        margin: 0px 4px 0 4px;
        width: 56px;
      }
    }

    .image-cell.first-cell {
      min-width: 70px;
      width: 70px;
    }

    .workheld-icons.work-step-icon {
      font-size: 24px;
    }

    .dnd-assigned-span-equipment {
      display: flex;
      align-self: center;
      margin-right: 0px;
      min-width: 40px;
      height: 28px;
    }

    .workheld-icons.dnd-header-icon {
      margin-left: 1px;
    }

    // SORT POSITION
    .mat-mdc-header-cell.sort-position-header {
      max-width: 42px;
      font-size: 16px;
    }

    .mat-mdc-cell.sort-position-cell {
      max-width: 42px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      .sort-position {
        background-color: mat.get-color-from-palette($accent);
        min-width: 34px;
        min-height: 34px;
        border-radius: 50%;
        color: mat.get-color-from-palette($accent, default-contrast);
        text-align: center;
        line-height: 2;
        font-size: 18px;
        font-weight: 600;
      }
    }

    // MODEL
    .mat-mdc-header-cell.model-header {
      max-width: 192px;
    }

    .mat-mdc-cell.model-cell {
      max-width: 192px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .mat-mdc-cell.material-attached-cell {
      max-width: 40px;
    }

    .mat-mdc-cell.model-cell.model-cell-expanded {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .mat-mdc-header-cell.date-header {
      max-width: 128px;
    }

    .mat-mdc-cell.date-cell {
      max-width: 128px;
    }

    .mat-mdc-header-cell.c-h-header {
      max-width: 40px;

      .workheld-icons.c-h-icon {
        margin: auto;
      }
    }

    .mat-mdc-cell.c-h-cell {
      max-width: 40px;

      ng-bee-checklist-definition-static-data {
        margin: auto;
      }
    }

    // STATUS
    .mat-mdc-header-cell.status-header {
      max-width: 112px;
    }

    .mat-mdc-cell.status-cell {
      max-width: 112px;
    }

    // EDIT
    .mat-mdc-header-cell.edit-header {
      max-width: 56px;
    }

    .mat-mdc-cell.edit-cell {
      max-width: 56px;
    }

    .work-step-edit-button {
      // margin-left: 8px;
      text-overflow: ellipsis;
      overflow: hidden;
      padding: 0 4px;
      min-width: 40px;
    }

    .dnd-assigned {
      width: 100%;
      height: 44px;
      border-radius: 0px;
      border: dashed 1px transparent;
    }

    .dnd-assigned.w-h-dnd-placeholder-util {
      width: 100%;
      height: 44px;
      border: 1px mat.get-color-from-palette($primary, 300) dashed;
      border-radius: 0px;
      background-color: mat.get-color-from-palette($primary, 50);
    }
  }
}

.standard-padding-cell {
  padding: 0px 0px !important;
}

.dnd-container-start {
  width: 100%;
  height: 44px;
  border-radius: 0px;
  border-left: 1px var(--primary-color-300) dashed;
  border-top: 1px var(--primary-color-300) dashed;
  border-bottom: 1px var(--primary-color-300) dashed;
  background-color: var(--primary-color-50);
}

.dnd-container-middle {
  width: 100%;
  height: 44px;
  border-radius: 0px;
  border-top: 1px var(--primary-color-300) dashed;
  border-bottom: 1px var(--primary-color-300) dashed;
  background-color: var(--primary-color-50);
}

.dnd-container-end {
  width: 100%;
  height: 44px;
  border-radius: 0px;
  border-top: 1px var(--primary-color-300) dashed;
  border-bottom: 1px var(--primary-color-300) dashed;
  border-right: 1px var(--primary-color-300) dashed;
  background-color: var(--primary-color-50);
}

.mat-mdc-row {
  position: relative !important;
}
