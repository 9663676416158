// APP

@mixin case-detail-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);

  .case-detail-card-wrapper {
    max-height: calc(100vh - 112px);
    overflow: auto;
  }

  .case-detail-card {
    padding: 0px;
    border-radius: 0px;
    margin: $padding__page;

    ng-bee-worker-static-data.work-object-resp-tech {
      .worker-chip {
        max-width: 96%;
      }
    }

    // TOP HEADER
    .case-detail-card-header {
      .new_detail-actions-wrapper {
        display: flex;
        align-items: center;
        button {
          mat-icon {
            margin: 0 !important;
          }
        }
      }
    }

    // BODY DATA
    .case-detail-card-body {
      padding: $padding__page;

      .work-object-summary-wrapper {
        max-height: 500px;
        overflow-x: hidden;
        border: 1px solid transparent;

        height: 100%;

        > span {
          height: 100%;
        }
      }

      .case-summary-label-row {
        margin-top: 8px;
      }
    }
    .case-detail-wrapper {
      padding: $padding__page;
    }
  }

  ng-bee-work-step-master-data-table.case-detail-work-step-master-data-table {
    .ng-bee-list {
      margin-bottom: 0px;
      min-height: unset;

      .data-table-container {
        min-height: 282px;
      }
      .data-table {
        min-height: calc(100vh - 812px);
      }
    }
  }

  ng-bee-w-h-agm-core-location-map.case-detail-w-h-agm-core-location-map
    .location-map {
    agm-map {
      height: 244px;
    }
  }

  ng-bee-entry-data-table.case-detail-entry-data-table {
    .entry-data-table-container {
      .data-table-container {
        min-height: 282px;
      }
      .data-table {
        min-height: calc(100vh - 816px);
      }
    }
  }

  ng-bee-checklist-instance-data-table.case-detail-entry-data-table
    .checklist-instance-data-table {
    .checklist-instance-data-table-container {
      .data-table-container {
        min-height: 282px;
      }
      .data-table {
        min-height: calc(100vh - 816px);
      }
    }
  }

  ng-bee-entry-file-data-table.case-detail-entry-data-table
    .entry-file-data-table {
    .entry-file-data-table-container {
      .data-table-container {
        min-height: 282px;
      }
      .data-table {
        min-height: calc(100vh - 816px);
      }
    }
  }
  ng-bee-report-file-data-table.case-detail-entry-data-table {
    .report-file-data-table-container {
      .data-table-container {
        min-height: 282px;
      }
      .data-table {
        min-height: calc(100vh - 816px);
      }
    }
  }

  w-h-event-log-data-table.case-detail-entry-data-table
    .w-h-event-log-data-table {
    .data-table-container {
      min-height: 282px;
    }

    .data-table {
      min-height: calc(100vh - 728px);
    }
  }
}

.mat-mdc-tab-group {
  height: 100%;

  .mat-mdc-tab-body-wrapper {
    height: 100%;
  }
}

::ng-deep {
  .mat-mdc-tab-body-content {
    overflow: hidden !important;
  }
}
