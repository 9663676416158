// APP

@mixin work-object-status-overview-board-mixin($theme) {
  .actions-container {
    display: flex;
    align-items: center;
    .status-overview-search-wrapper {
      width: 100%;
    }
    .w-h-status-overview-search {
      width: 99.5% !important;
    }

    .create-new-wrapper {
      width: 20%;
      padding: 0 4px;
      display: inline-flex;
      min-height: 34.5px;
      vertical-align: super;
      flex: 0 0 calc(100% / var(--numOfColumns));
      justify-content: center;
      .create-new-button {
        width: 100%;
        // color: #fff;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }

  .no-data-message {
    text-align: center;
    padding: $padding__page;
  }

  .work-object-status-overview-body {
    padding: 0px;
  }

  .load-more-button-wrapper {
    text-align: center;
    padding: 8px 0px;
  }

  .status-span {
    margin-left: 33px;
    vertical-align: top;
    position: relative;
  }

  // STATUS LENGTH 5
  .status-span.length5 {
    display: inline-block;
    width: 20%;
    margin: 0;
  }

  // STATUS LENGTH 4
  .status-span.length4 {
    margin: 0;
    display: inline-block;
    width: 25%;
  }

  // STATUS LENGTH 3
  .status-span.length3 {
    display: inline-block;
    margin: 0;
    width: 33.3333333%;
  }
}
