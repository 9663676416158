// APP

@mixin w-h-maintenance-plan-page-fix-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);

  .w-h-maintenance-plan-page {
    ng-bee-customer-autocomplete.maintenance-plan-customer-autocomplete {
      max-width: 300px;

      .dropdown {
        max-width: 23.8%;
        min-width: 23.8%;
        top: 66px;
      }
    }

    .before-button {
      border-radius: 25px 0px 0px 25px;
    }

    .before-after {
      border-radius: 0px 25px 25px 0px;
    }

    .range-controls-wrapper {
      margin: 16px 0;
      border-radius: 25px;
      padding: 0 44px;
      width: fit-content;
      margin-left: 16px;
    }

    .w-h-data-table.maintenance-plan-data-table {
      min-height: calc(100vh - 314px);
    }

    .maintenance-plan-filter {
      margin: 16px 16px 0 16px;
      padding: 0;
      border-radius: 0;
    }

    .maintenance-plan-table {
      border-radius: 0;
      padding: 0;
      margin: 16px;
    }

    .range-controls {
      margin-top: 8px;
    }

    .export-button-span {
      margin: auto;

      .w-h-file-export-button-wrapper {
        padding: 0px $padding__page;

        .w-h-file-export-timereport {
          text-align: center;

          .w-h-export-button {
            padding: 0px $padding__page;
            border-radius: 4px;
            margin: auto;

            .workheld-icons.export-icon {
              vertical-align: middle;
              width: 34px;
              height: 24px;
              font-size: 24px;
            }

            .timereport-label {
              padding: 4px;
            }

            mat-spinner {
              display: inline-block;
            }
          }
        }
      }
    }
  }
}