// APP

@mixin material-detail-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);
  .material-detail-card {
    padding: 0px;
    border-radius: 0px;
    margin: $padding__page;

    // TOP HEADER
    .detail-card-header.material-detail-card-header {
      // padding: 16px 8px;
    }

    .material-detail-span {
      max-width: calc(100% - 284px);
    }

    .material-detail-card-body {
      padding: $padding__page;
      min-height: calc(100vh - 270px);

      .image-wrapper-span {
        padding: 0px $padding__page 0px 0px;
        .image-wrapper {
          min-width: 270px;
        }
      }
    }
  }
}
