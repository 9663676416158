.mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after,
.mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-indeterminate::after {
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(
    --accent-color-50
  );
  color: var(--mat-minimal-pseudo-checkbox-selected-checkmark-color);
}

.mcd-button.mat-accent {
  .workheld-icons {
    color: var(--accent-color-50) !important;
  }
}

.mat-mdc-icon-button {
  --mat-icon-button-ripple-color: unset !important;
}
.mat-expansion-panel-header-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: #f4f4f4 !important;
}

// Material's TABS component
// set the width of the tab to the content width
// and removed unnecessary padding
.mdc-tab {
  min-width: unset !important;
  padding-right: 0px !important;
  padding-left: 0px !important;
}

mat-chip {
  overflow: hidden !important;
}

.mdc-evolution-chip-set .mdc-evolution-chip {
  margin-left: 4px !important;
  margin-right: 4px !important;
}

.mdc-evolution-chip-set .mdc-evolution-chip-set__chips {
  margin-left: 0px !important;
}

.mat-mdc-chip.mdc-evolution-chip--with-trailing-action
  .mat-mdc-chip-action-label {
  display: block !important;
}

// used in stacked-avatars component for list items
.stacked-avatars {
  .mdc-list-item__primary-text {
    padding: 4px 2px;
  }
}
.mat-mdc-dialog-actions {
  justify-content: flex-end !important;
}

//calendar date on hover color
.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
    .mat-calendar-body-comparison-identical
  ) {
  color: #fff;
}

//calendar selected date color
.cdk-keyboard-focused
  .mat-calendar-body-active
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
    .mat-calendar-body-comparison-identical
  ),
.cdk-program-focused
  .mat-calendar-body-active
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
    .mat-calendar-body-comparison-identical
  ) {
  color: #fff;
}

.mdc-dialog__container {
  .detail-actions-wrapper {
    justify-content: flex-end;
  }
}

.mat-mdc-header-cell {
  font-size: 14px;
}

.mat-typography {
  font-family: "Helvetica", Arial, sans-serif !important;
}

.cdk-dropzone:has(.dnd-placeholder-box.active) {
  height: 100%;
}
// dnd placeholder style
.dnd-placeholder-box {
  display: none;
  border: 2px dashed #5b83c0 !important;
  border-radius: 4px;
  left: 0;
  height: 100%;
  min-height: 100%;
  width: 100%;
  overflow: hidden;
  color: #172b4d;
  gap: 16px;
  position: absolute;
  top: 0;

  &.workobject.active {
    background-color: #deebff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 38px;
    box-sizing: border-box;
    flex-direction: column;
    z-index: 2;
    text-align: center;
  }

  &.workstep.active {
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    flex-direction: column;
    z-index: 999;
    text-align: center;
  }
}
