// APP

@mixin w-h-search-term-mixin($theme) {
  .mat-mdc-form-field-infix {
    line-height: 1.8;
  }
  
  .search-field {
    max-width: 400px;
    width: 100%;
  }

  .close-icon {
    color: var(--primary-color-500);
  }
}
