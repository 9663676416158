// APP

@mixin mat-dialog-entry-detail-entry-component-mixin($theme) {
    $primary: map-get($theme, color, primary);
    $accent: map-get($theme, color, accent);
    $warn: map-get($theme, color, warn);
  
    .entry-detail-mat-dialog {
       

      ng-bee-entry-process-form.entry-detail-mat-dialog-entry-process-form .entry-process-form {
        .entry-process-form-body {
          padding: $padding__page $padding__page 0 $padding__page;
        }
      }
  
      .mat-mdc-card-avatar.workheld-icons.header-icon {
        border-radius: 0px;
        height: 48px;
        width: 64px;
        text-align: center;
        line-height: 1.5;
        font-size: 34px;
        background-color: mat.get-color-from-palette($primary, 500);;
        color: #fff;
      }
  
      .entry-detail-mat-dialog-body {
        padding: 15px 15px 0 15px;
      }
  
      .mat-dialog-actions.dialog-action-wrapper {
        padding: 15px;
        min-height: unset;
      }
      .w-h-default-button.dialog-button {
        max-width: 45%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .mat-accent.dialog-button {
        max-width: 45%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
  
