// APP

@mixin work-order-master-data-table-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);
  .work-order-master-data-table {
    // padding: $padding__page;
    .work-order-master-data-table-container {
      ng-bee-list.work-order-master-data-ng-bee-list .ng-bee-list {
        .mat-mdc-header-row {
          min-height: 40px;
        }
      }

      .mat-mdc-row.w-h-dnd-placeholder-util {
        border: 1px dashed mat.get-color-from-palette($primary, 100);
        background-color: mat.get-color-from-palette($primary, 50);
        max-height: 49px;
      }

      .workheld-icons.sap-sync-icon {
        background-color: mat.get-color-from-palette($accent);
        border-radius: 50%;
        margin-right: 16px;
        color: mat.get-color-from-palette($accent, default-contrast);
      }

      .mat-paginator.paginator {
        display: block;
        background: $theme-app-bar;
        min-height: 52px;
        .mat-paginator-container {
          min-height: 52px;
        }
        .mat-paginator-page-size-select {
          margin: 0px 4px 0 4px;
          width: 56px;
        }
      }
    }
  }
}

.mat-mdc-row {
  position: relative !important;
}
