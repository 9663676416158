// APP

@mixin work-object-card-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);

  .allow-cr {
    white-space: pre;
    height: auto;
  }

  .work-object-card {
    padding: 4px;
    z-index: 10;
    border-radius: 12px !important;
    box-shadow: 2px 4px 15px rgb(171 189 207 / 25%);
    transition: 0.2s;
    min-height: 179px;
    .actions-group {
      display: none;
      align-items: flex-end;
      gap: 8px;
    }

    .search-filter-pill {
      position: absolute;
      top: 0;
      right: 0;
      background: var(--accent-color-500);
      border-radius: 16px;
      padding: 0px 8px;
      color: var(--accent-color-50);
      margin-top: -0.5em;

      max-width: 240px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &:hover {
      background-color: #ebebeb;
      box-shadow: none;
      transition: 0.2s;
      transform: scale(0.99);
      cursor: pointer;

      .worker-container.dynamic {
        opacity: 0;
      }

      .actions-group {
        display: flex;
      }
    }

    button.reject-wrapper {
      color: white;
      border-radius: 5px;
    }

    .w-h-accept-btn {
      background-color: #25882a !important;
      color: #ffffff !important;
      padding: 0 10px;
      height: 40px;
    }

    .workheld-icons.sap-sync-icon {
      background-color: mat.get-color-from-palette($accent);
      border-radius: 50%;
      margin-top: 4px;
      color: mat.get-color-from-palette($accent, default-contrast);
    }

    .work-object-card-header {
      padding: 10px;
      border-radius: 8px;
      background-color: #ebebeb;
      overflow: hidden;
      color: mat.get-color-from-palette($primary);
      gap: 12px;
      .w-h-reject-btn {
        padding: 0px 0;
        display: inline-block;
        line-height: 24px;
        height: 28px;
        width: 28px;
      }

      .work-object-card-header-span {
        padding: 0px;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .defect-count-span {
        vertical-align: top;
        border-radius: 25%;
        margin-right: 5px;
        .defect-entry-count {
          border-radius: 25%;
          margin-top: 4px;
          padding: 2px;
          display: inline-block;
          background-color: #eee;
          color: #fff;
          min-width: 54px;
          .workheld-icons.defect-icon {
            font-size: 20px;
            color: mat.get-color-from-palette($primary);
            vertical-align: middle;
          }

          .defect-count {
            padding: 1px;
            display: inline-block;
            border-radius: 50%;
            background-color: mat.get-color-from-palette($accent);
            color: mat.get-color-from-palette($accent, default-contrast);
            min-width: 18px;
            text-align: center;
          }
        }
      }

      .defect {
        display: inline-flex;
        flex-direction: row;
        border: none;
        height: 28px;
        outline: none;
        padding: 0;
        font-size: 14px;
        white-space: nowrap;
        align-items: center;
        border-radius: 16px;
        vertical-align: middle;
        text-decoration: none;
        justify-content: center;
        cursor: pointer;
      }
      .defect-head {
        display: flex;
        position: relative;
        overflow: visible;
        font-size: 1.25rem;
        flex-shrink: 0;
        align-items: center;
        user-select: none;
        justify-content: center;
        width: 28px;
        height: 28px;
        margin-right: -4px;
        margin-left: 2px;
        color: mat.get-color-from-palette($accent);
      }
      .defect-content {
        cursor: inherit;
        white-space: nowrap;
        position: absolute;
        top: -1px;
        left: 13px;
      }

      .defect-count {
        background-color: mat.get-color-from-palette($warn);
        color: #ffffff;
        border-radius: 50%;
        text-align: center;
        display: block;
        height: 16px;
        width: 16px;
        font-size: 10px;
        line-height: 16px;
      }

      .workheld-icons.work-object-card-header-icon {
        font-size: 32px;
      }

      .card-extId {
        margin-bottom: 2px;
        // margin-left: 19px;
      }
    }
    .work-object-card-body {
      padding: 10px;
      border-radius: 0px 0px 25px 25px;
      .work-object-card-body-row {
        margin-bottom: 8px;
      }

      .ng-bee-customer-static-data.work-object-card-customer-static-data
        .w-h-label {
        margin-bottom: 2px;
      }
      .ng-bee-w-h-date-static-data.work-object-card-date-static-data
        .w-h-label {
        margin-bottom: 2px;
      }

      .work-object-card-extra-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;

        .top-row {
          gap: 8px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
        .work-object-card-extra {
          text-align: end;

          .workheld-icons.card-extra-icon {
            margin-top: 4px;
            color: mat.get-color-from-palette($accent);
          }
        }

        .target-state-container {
          display: flex;
          height: 100%;
          align-items: flex-end;
          justify-content: flex-end;
          p {
            background-color: mat.get-color-from-palette($primary, 100);
            color: mat.get-color-from-palette($primary, 500);
            padding: 2px 4px;
            border-radius: 4px;
            margin: 0px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 100%;
            max-width: 155px;
            margin-bottom: 12px;
          }
        }

        .worker-container {
          display: flex;
          margin-top: auto;
          flex-wrap: nowrap;
          justify-content: flex-end;
          align-items: center;

          .workers-leftover-container {
            display: inline-block;
          }
        }
      }
    }
  }

  .work-object-card.yellow-outline {
    outline: #ffc107 solid 4px;
  }

  .work-object-card.red-outline {
    outline: #dc3545 solid 4px;
  }

  .overdue {
    border-radius: 16px;
    color: #fff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    left: 0;
    padding: 30px 8px 0px 4px;
    box-sizing: border-box;
    margin-top: -4.5em;
    transition: 0.4s;
    position: absolute;
    transform: scale(0.99);
    font-size: 12px;

    &.yellow-outline {
      background-color: #ffc107;
      outline: #ffc107 solid 4px;
    }

    &.red-outline {
      background-color: #dc3545;
      outline: #dc3545 solid 4px;
    }
  }
}

.w-o-card-wrapper {
  position: relative;
  &:hover {
    .overdue {
      transition: 0.4s;
      margin-top: -2.65em;
      padding-left: 14px;
      transform: scale(0.99);
    }
  }
}

//region loading skeleton
.skeleton-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  & > div {
    display: flex;
    gap: 16px;
    align-items: flex-start;
  }
}

.skeleton-items {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 4px;
  width: 100%;

  div {
    border-radius: 8px;
    background: rgb(231, 231, 231);
    background-image: linear-gradient(
      90deg,
      rgb(231, 231, 231),
      rgb(217, 217, 217),
      rgb(231, 231, 231)
    );
    background-size: 80px 100%;
    background-repeat: no-repeat;
    background-position: left -80px top 0;
    animation: shine 0.9s ease infinite;
    width: 100px;
    height: 25px;
    &:first-child {
      width: 100%;
    }
    &:last-child {
      width: 90%;
    }

    &.skeleton-circle {
      border-radius: 50%;
      width: 28px;
      height: 24px;
    }
  }
}

@keyframes shine {
  to {
    background-position: right -80px top 0;
  }
}
//endregion
