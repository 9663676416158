$orange: #f4af01;
$orange-light: #fff8e4;
$green: #008000;
$green-light: #e3f6e3;
$red: #ff0000;
$red-light: #ffe3e3;
$black: #000000;
$black-light: #ebebeb;
$blue: #0b66e4;
$blue-light: #dfedff;

.wh-status {
  padding: 2px 12px;
  border-radius: 16px;
  font-weight: bold;
  width: max-content;

  &.orange {
    background-color: $orange-light;
    color: $orange;
  }

  &.green {
    background-color: $green-light;
    color: $green;
  }

  &.red {
    background-color: $red-light;
    color: $red;
  }

  &.black {
    background-color: $black-light;
    color: $black;
  }

  &.blue {
    background-color: $blue-light;
    color: $blue;
  }
}
