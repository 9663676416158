// APP

@mixin equipment-create-edit-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);

  ng-bee-customer-autocomplete.equipment-crud {
    .dropdown.customer-dropdown {
      top: 40px;
      width: 100%;
      // position: relative;
    }
  }

  .equipment-create-edit-form-wrapper {
    overflow: auto;
    min-height: calc(100vh - 116px);
  }

  .equipment-create-edit-card {
    border-radius: 0px;
    padding: 0px;
    // EXCEPTION - only here margine is set
    margin: $padding__page;

    .customer-wrapper {
      position: relative;
    }

    .detail-card-header.equipment-create-edit-card-header {
      padding: 0px;
    }

    .image-wrapper-span {
      padding-right: $padding__page;
    }

    .eq-left-span {
      padding-right: $padding__page;
    }

    .eq-right-span {
      padding-left: $padding__page;
    }

    .eq-crud-button {
      margin-left: 16px;
    }

    .map-form-wrapper {
      min-height: calc(100vh - 340px);
    }
    .flex-property-wrapper {
      min-height: calc(100vh - 340px);
    }
  }
}

.mat-grid-tile {
  overflow: visible !important;
}
