// APP

@mixin work-object-data-table-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);

  ng-bee-customer-autocomplete.work-object-data-table-customer-autocomplete {
    // margin-left: 16px;
    text-align: start;
    .dropdown {
      max-width: 21.7%;
      min-width: 21.7%;
      top: 132px;
    }
  }

  .work-object-data-table {
    .mat-slide-toggle.archive-toggle {
      margin-left: 16px;
    }

    .workheld-icons.sap-sync-icon {
      background-color: mat.get-color-from-palette($accent);
      border-radius: 50%;
      margin-right: 16px;
      color: mat.get-color-from-palette($accent, default-contrast);
    }

    .work-object-data-table-container {
      // margin-bottom: $padding__page;
      .data-table-toolbar {
        height: 64px;
        padding-right: $padding__page;
        .left-side-wrapper {
          height: 100%;
        }
        .right-side-wrapper {
          text-align: end;
          align-self: center;
        }
      }

      table {
        width: 100%;
      }

      .mat-mdc-header-cell.work-object-data-table-status-header-cell {
        max-width: 156px;
      }
      .mat-mdc-cell.work-object-data-table-status-cell {
        max-width: 156px;
      }

      .mat-mdc-header-cell.ext-id-header {
        max-width: 192px;
      }

      .mat-mdc-cell.ext-id-cell {
        max-width: 192px;
      }

      .mat-mdc-header-cell.case-extra-cell {
        max-width: 192px;
      }
      .mat-mdc-cell.case-extra-cell {
        max-width: 192px;
      }

      .mat-mdc-header-cell.customer-extra-cell {
        max-width: 292px;
      }
      .mat-mdc-cell.customer-extra-cell {
        max-width: 292px;
      }

      .mat-mdc-header-cell.date-header {
        max-width: 168px;
      }

      .mat-mdc-cell.date-cell {
        max-width: 168px;
      }

      .work-object-icon {
        color: mat.get-color-from-palette($primary, 500);;
      }
      .mat-mdc-row:hover {
        .work-object-icon {
          color: #fff;
        }
      }

      .customer-cell {
        padding-right: 8px;
        width: 100%;
        ng-bee-customer-static-data {
          width: 100%;
        }
      }
    }
  }
}
