// APP

@mixin customer-create-edit-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);
  .customer-create-edit-card {
    border-radius: 0px;
    padding: 0px;
    margin: $padding__page;

    .mat-icon.header-icon {
      border-radius: 0px;
      height: 64px;
      width: 64px;
      text-align: center;
      line-height: 1.5;
      font-size: 40px;
      background-color: mat.get-color-from-palette($primary, 500);;
      color: mat.get-color-from-palette($primary, default-contrast);;
    }

    .customer-create-edit-card-body {
      padding: $padding__page;
      .customer-crud-button {
        margin-left: 16px;
      }
    }
  }
}
