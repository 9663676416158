// APP

@mixin mat-dialog-image-preview-entry-component-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);
  .w-h-image-preview-mat-dialog {
    .w-h-image-preview-header {
      padding: 8px;
      margin-top: 4px;
      .file-meta-data {
        height: 100%;
        font-size: 18px;
        vertical-align: super;
        color: mat.get-color-from-palette($primary, 500);
      }
      .img-icon {
        color: mat.get-color-from-palette($primary, 500);
        margin-right: 8px;
      }
      .download-img-icon {
        color: mat.get-color-from-palette($accent);
        margin-right: 12px;
        cursor: pointer;
      }
      .close-preview-icon {
        cursor: pointer;
      }
    }
    .mat-divider {
      border-top-color: mat.get-color-from-palette($primary);
    }
    .w-h-image-preview-body {
      padding: 12px;
      overflow: hidden;
      max-height: calc(100vh - 20vh);
      img.image-preview {
        display: block;
        max-height: calc(100vh - 22vh);
        height: auto;
        margin: auto;
        max-width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
        max-width: -moz-available; /* WebKit-based browsers will ignore this. */
        max-width: 100%;
      }
    }

    .w-h-image-preview-footer {
      padding: 0px 16px 16px 16px;
    }
    .mat-mdc-dialog-container {
      display: block;
      padding: 0px;
      border-radius: 0px;
      box-sizing: border-box;
      overflow: auto;
      outline: 0;
      width: 100%;
      height: 100%;
      min-height: inherit;
      max-height: inherit;
    }
  }
}
