// APP

@mixin mat-dialog-create-work-object-template-entry-component-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);
  
  .mdc-list-item__primary-text {
    display: flex;
    gap: 12px;
  }

  .detail-card-header {
    align-items: center;
  }

  .create-work-object-template-mat-dialog {
    .create-work-object-template-form-wrapper {
      margin-top: 8px;
      padding: 0px 16px;
    }

    .mat-list.work-order-list {
      min-height: 404px;
      max-height: 404px;
      overflow-y: auto;
      margin-bottom: $padding__page;
    }

    .mat-dialog-actions.dialog-action-wrapper {
      padding: $padding__page;
      min-height: unset;
    }

    .w-h-default-button.dialog-button {
      max-width: 45%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .mat-accent.dialog-button {
      max-width: 45%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .mat-mdc-list {
    padding: 16px !important;
  }
}