// APP

@mixin standing-order-create-edit-form-mixin($theme) {

  ng-bee-customer-autocomplete.standing-order-crud .customer-drop-down {
    .dropdown {
      min-width: 34.5%;
    }
    .customer-input  {
      margin-top: unset;
    }
  }

  .standing-order-create-edit-form {
    padding: $padding__page;
    // width: 100%;

    .workheld-icons.w-h-expand-icon {
      font-size: 24px;
    }

    .left-column-width {
      width: 90%;
    }

    .mat-mdc-form-field.form-field-full-width {
      width: 100%;
    }

    .standing-order-crud-button {
      margin-left: $padding__page;
    }
  }
}
