// APP

@mixin equipment-detail-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);

  .node-tree-invisible {
    display: none;
  }

  .skill-count {
    background-color: #e0e0e0;
    border: solid 2px mat.get-color-from-palette($accent);
    min-width: 24px;
    min-height: 24px;
    border-radius: 50%;
    text-align: center;
    line-height: 1.7;
    display: inline-block;
  }

  .node-tree ul,
  .node-tree li {
    margin-top: 0;
    margin-bottom: 0;
    list-style-type: none;
  }

  /*
   * This padding sets alignment of the nested nodes.
   */
  .node-tree .mat-nested-tree-node div[role="group"] {
    padding-left: 40px;
  }

  .mat-nested-tree-node {
    position: relative;
  }

  /*
   * Padding for leaf nodes.
   * Leaf nodes need to have padding so as to align with other non-leaf nodes
   * under the same parent.
   */
  .node-tree div[role="group"] > .mat-tree-node {
    border-bottom: 1px solid #eee;
    // padding-left: 40px;
  }

  .decomissioned-node {
    --disabled-color: #e8e8e8;

    color: var(--disabled-color) !important;

    span {
      color: var(--disabled-color) !important;
    }

    i {
      color: var(--disabled-color) !important;
    }

    .skill-count {
      border: solid 2px var(--disabled-color) !important;
    }

    mat-icon {
      color: var(--disabled-color) !important;
    }
  }

  .mat-tree-node {
    position: relative;
    .node-name-with-buttons {
      width: 100%;
      padding-right: 12px;
      height: 48px;
      display: flex;

      .drag-node-area {
        border: 1px mat.get-color-from-palette($primary, 300) dashed;
        border-radius: 0px;
        background-color: mat.get-color-from-palette($primary, 50);
        height: 30px;
      }
    }

    .node-main-menu {
      display: flex;
    }
  }

  .assignment-span {
    max-width: 16%;
    min-height: 100%;
    ng-bee-w-h-dnd-assigment-chip-list-util.equipment-detail-dnd-assigment-chip-list-util
      .mat-chip-list-stacked-wrapper {
      padding: $padding__page 24px $padding__page $padding__page;
      overflow: auto;
      max-height: calc(100vh - 248px);
    }
  }

  .add-contact-line {
    text-decoration: underline;
    color: mat.get-color-from-palette($accent);
    line-height: 26px;
    text-align: center;
    cursor: pointer;
  }

  .add-contact-box {
    border: 1px solid #e3e3e3;
    border-radius: 8px;
    line-height: 44px;
    text-align: center;
    cursor: pointer;
    text-decoration: underline;
    color: mat.get-color-from-palette($accent);
  }

  .add-contact-box:hover {
    background-color: mat.get-color-from-palette($accent);
    color: mat.get-color-from-palette($accent, default-contrast);
  }

  .eq-header {
    display: flex;
    align-items: center;
    gap: 16px;

    .workheld-icons.equipment-header-icon {
      color: #fff;
      font-size: 34px !important;
    }
  }

  .equipment-detail-card-span {
    max-height: calc(100vh - 112px);
    overflow: auto;

    .eq-crud-button {
      margin-right: $padding__page;
    }

    .equipment-detail-card {
      padding: 0px;
      margin: $padding__page;
      border-radius: 0px;

      .node-tree ul,
      .node-tree li {
        margin-top: 0;
        margin-bottom: 0;
        list-style-type: none;
      }

      li {
        i.workheld-icons {
          z-index: 10;
        }

        img {
          z-index: 10;
        }

        div {
          z-index: 10;
        }
      }

      li:hover {
        .workheld-icons[color="primary"] {
          color: mat.get-color-from-palette($primary, default-contrast);
        }

        i.workheld-icons {
          color: mat.get-color-from-palette($primary, default-contrast);
        }

        div {
          color: mat.get-color-from-palette($primary, default-contrast);
        }
      }

      /* silly UX, they want a border in the full width of the tree between tree nodes */
      .node-tree {
        position: relative;
      }

      .mat-tab-body-content {
        height: 100%;
        overflow: auto;
      }

      li::before {
        height: 2.9rem;
        position: absolute;
        left: 0;
        right: 0;
        border-bottom: #eee 1px solid;
        content: " ";
      }

      li:hover::before {
        background-color: mat.get-color-from-palette($primary, 400);
        color: mat.get-color-from-palette($primary, default-contrast);
      }

      .top-bar-buttons {
        display: flex;

        mat-icon {
          overflow: visible !important;
        }

        .decomission {
          background-color: orange;
        }

        .inactive {
          background-color: lightgray;
        }

        .delete {
          background-color: lightgray;
        }
      }

      .decommissioned-detail-card-header {
        background-color: gray;
      }
      // TOP HEADER
      .equipment-detail-card-header {
        display: flex;
        min-height: 50px;
        padding-left: 16px;
        padding-right: 16px;

        .operating-state-wrapper {
          text-align: right;

          .state-text {
            color: #fff;
          }
        }
      }

      .image-wrapper {
        min-height: 275px;
      }

      .chip-skill-container {
        margin: $padding__page;
        max-height: 250px;
        border: 3px white solid;
        overflow-y: auto;
        display: flex;
        flex-direction: row;
        min-height: 250px;
      }

      .skill-detail-draggable-area {
        background-color: #e8e8e8;
        border: 3px var(--accent-color-500) dashed;
        display: flex;
        z-index: 10;
        opacity: 0.8;
      }

      // BODY DATA
      .equipment-detail-card-body {
        padding: $padding__page;
        display: flex;

        .eq-info-span {
          padding: $padding__page;
          --grid-layout-gap: 5px;
          --grid-column-count: 2;
          --grid-item--min-width: 120px;

          /**
           * Calculated values.
           */
          --gap-count: calc(var(--grid-column-count) - 1);
          --total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));
          --grid-item--max-width: calc(
            (100% - var(--total-gap-width)) / var(--grid-column-count)
          );

          display: grid;
          grid-template-columns: repeat(
            2,
            minmax(
              max(var(--grid-item--min-width), var(--grid-item--max-width)),
              1fr
            )
          );
          grid-template-rows: min-content;
          grid-gap: var(--grid-layout-gap);
        }
      }

      ng-bee-w-h-agm-core-location-map.equipment-detail-w-h-agm-core-location-map
        .location-map {
        agm-map {
          height: 246px;
        }
      }

      ng-bee-work-object-data-table.equipment-work-object-data-table-component
        .work-object-data-table {
        .work-object-data-table-container {
          .data-table-container {
            min-height: 316px;

            .data-table {
              min-height: calc(100vh - 722px);
            }
          }
        }
      }

      ng-bee-entry-data-table.equipment-entry-data-table-component
        .entry-data-table {
        .entry-data-table-container {
          .data-table-container {
            min-height: 316px;

            .data-table {
              min-height: calc(100vh - 722px);
            }
          }
        }
      }

      ng-bee-checklist-instance-data-table.equipment-checklist-instance-data-table
        .checklist-instance-data-table {
        .checklist-instance-data-table-container {
          .data-table-container {
            min-height: 316px;

            .data-table {
              min-height: calc(100vh - 722px);
            }
          }
        }
      }

      ng-bee-report-file-data-table.equipment-report-file-data-table
        .report-file-data-table {
        .report-file-data-table-container {
          .data-table-container {
            min-height: 316px;

            .data-table {
              min-height: calc(100vh - 722px);
            }
          }
        }
      }

      .equipment-detail-draggable-area {
        background-color: #e8e8e8;
        border: 3px var(--accent-color-500) dashed;
        position: absolute;
        height: 98%;
        width: 98%;
        z-index: 10;
        top: 1%;
        left: 1%;
        opacity: 0.8;
      }

      // IN TABS
      .equipment-detail-container {
        padding: $padding__page;
        min-height: calc(100vh - 634px);
        align-items: start;

        .equipment-detail-column {
          display: flex;
          flex-direction: column;
          padding: 8px;

          min-height: 200px;
          min-width: 200px;
        }

        .equipment-description {
          width: 97%;
          resize: vertical;
        }

        .contact-box {
          display: flex;
          flex-direction: column;
          gap: 16px;

          .contact-field {
            line-height: 1;
            border: 1px solid #e3e3e3;
            border-radius: 8px;
            cursor: pointer;
            padding: 4px 0;
          }

          .contact-icon {
            color: mat.get-color-from-palette($accent);
            font-size: 30px;
          }
        }

        .contact-detail:hover {
          background-color: mat.get-color-from-palette($accent);
          color: #fff;
          border-radius: 8px;
          cursor: pointer;
        }

        .contact-detail {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding-bottom: 8px;
        }

        .contact-text {
          color: mat.get-color-from-palette($accent);
          text-decoration: underline;
        }

        .q-r-code-img {
          display: block;
          height: 100%;
          max-width: 200px;
        }

        .q-r-code-img-export-button-wrapper {
          text-align: center;

          .q-r-code-img-export-button {
            color: mat.get-color-from-palette($accent, 500);
            text-overflow: ellipsis;
            overflow: hidden;
            width: 100%;
          }
        }

        .flex-properties {
          --grid-layout-gap: 10px;
          --grid-column-count: 2;
          --grid-item--min-width: 220px;

          /**
           * Calculated values.
           */
          --gap-count: calc(var(--grid-column-count) - 1);
          --total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));
          --grid-item--max-width: calc(
            (100% - var(--total-gap-width)) / var(--grid-column-count)
          );

          display: grid;
          grid-template-columns: repeat(
            auto-fill,
            minmax(
              max(var(--grid-item--min-width), var(--grid-item--max-width)),
              1fr
            )
          );
          grid-gap: var(--grid-layout-gap);
        }
      }

      .bomstructure-wrapper {
        min-height: calc(100vh - 600px);
      }

      .equipment-detail-wrapper {
        padding: $padding__page;
        min-height: calc(100vh - 634px);

        .contact-person-wrapper {
          // min-width: 318px;
          margin-bottom: $padding__page;
        }

        .contact-box {
          // padding: 0 8px;
          // border: solid 2px mat.get-color-from-palette($primary, 200);
          .contact-field {
            line-height: 1;
            height: 64px;
            border: 1px solid #e3e3e3;
            border-radius: 8px;
            cursor: pointer;
            margin-bottom: $padding__page;
            padding: 4px 0;
          }

          .workheld-icons.contact-icon {
            color: mat.get-color-from-palette($accent);
            font-size: 24px;
          }
        }

        .assigned-worker-wrapper {
          // min-width: 318px;
          margin-bottom: $padding__page;

          ng-bee-w-h-dnd-placeholder-util.equipment-detail-dnd-placeholder-util
            .w-h-dnd-placeholder-util {
            height: 204px;
          }
        }

        .description-wrapper {
          padding: 0 $padding__page;
        }

        .qr-code-wrapper {
          .q-r-code-img-wrapper {
            min-height: 200px;

            .q-r-code-img {
              display: block;
              margin: auto;
              height: 100%;
              width: 60px;
              max-width: 50px;
            }
          }

          .q-r-code-img-export-button-wrapper {
            text-align: center;

            .q-r-code-img-export-button {
              color: mat.get-color-from-palette($accent, 500);
              text-overflow: ellipsis;
              overflow: hidden;
              width: 100%;
            }
          }
        }
      }
    }

    .right-side-wrapper {
      margin-left: 16px;
      text-align: end;
      align-self: center;
    }
  }
}

[aria-label="primary"] {
  min-width: 25px !important;
  background-color: rgb(237, 237, 237);
}

.loading-container {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.equipment-tab-group {
  .mat-mdc-tab-header {
    background-color: #f5f5f5 !important;
  }
  .mdc-tab__content {
    max-width: 99%;
    .mdc-tab__text-label {
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      display: block !important;
      white-space: nowrap;
      line-height: 1.9;
    }
  }
}

#wh-droplist-table {
  .cdk-drag-placeholder {
    display: none !important;
  }
}

.skill-dropzone {
  display: block;
  overflow-y: auto;
  height: 280px;
  box-sizing: border-box;
  padding-top: 8px;
}

.too-deep {
  min-width: 1200px;
  overflow: auto;
}
