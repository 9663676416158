// APP

@mixin mat-dialog-create-report-entry-component-mixin($theme) {
    $primary: map-get($theme, color, primary);
    $accent: map-get($theme, color, accent);
    $warn: map-get($theme, color, warn);

    .create-report-mat-dialog {
         

        .w-h-dialog-button {
            margin-right: $padding__page;
        }

        .report-body {
            min-height: 312px;
            max-height: 55vh;
            margin-right: -24px;
            margin-bottom: 16px;
            overflow-y: auto;
        }
    }
    .mat-dialog-actions.dialog-action-wrapper {
        padding: 15px;
        min-height: unset;
    }
    .w-h-default-button.dialog-button {
        max-width: 45%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .mat-accent.dialog-button {
        max-width: 45%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

}
