// APP

@mixin mat-dialog-contact-principal-entry-component-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);

  .contact-principal-mat-dialog {
    .contact-principal-mat-dialog-body {
      padding: 24px;

      .mat-stepper-horizontal.use-template-stepper {
        .mat-horizontal-content-container {
          overflow: hidden;
          padding: 0;
        }
      }

      .select-template-msg {
        text-align: center;
        vertical-align: middle;
        min-height: 559px;
      }

      .dialog-action-wrapper {
        padding: 0 $padding__page $padding__page $padding__page;
      }

      .selected-work-object-template {
        padding: 0 $padding__page;
      }

      .agm-core-location-form-wrapper {
        padding: 0 $padding__page;
        margin-bottom: 28px;
      }
    }

    .mat-dialog-actions.dialog-action-wrapper {
      padding: 15px;
      min-height: unset;
    }

    .w-h-default-button.dialog-button {
      max-width: 45%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .mat-accent.dialog-button {
      max-width: 45%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .w-h-dialog-button {
      margin-right: $padding__page;
    }
  }
}
