// APP

@mixin mat-dialog-checklist-definition-entry-component-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);

  .checklist-definition-mat-dialog {
     
    overflow: hidden;

    .checklist-definition-mat-dialog-body {
      padding: 0px;

      ng-bee-list.checklist-definition-mat-dialog-ng-bee-list .ng-bee-list {
        .mat-mdc-table.data-table {
          height: 490px;
          min-height: 490px;
        }
        margin-bottom: 0px;
        box-shadow: unset;
      }

      ng-bee-checklist-definition-detail.checklist-definition-mat-dialog-checklist-definition-detail
        .checklist-definition-detail-card {
        box-shadow: unset;
        margin: 0px;

        .checklist-definition-detail-card-body  {
          min-height: unset;
        }

        .check-definition-array-wrapper {
          min-height: 364px;
          max-height: 364px;
          overflow: auto;
          overflow-x: hidden;

          ng-bee-check-definition-create-edit-form .check-definition-create-edit-card {
            width: 99%;
            padding-right: $padding__page;
          } 
        }
      }

      .select-checklist-definition-msg {
        text-align: center;
        vertical-align: middle;
        min-height: 559px;
        padding: 0px $padding__page;
      }
    }

    .mat-mdc-row.selected-checklist-definition {
      background-color: mat.get-color-from-palette($primary, 200);
      .mat-mdc-cell {
        color: #fff;
      }
    }

    .mat-dialog-actions.dialog-action-wrapper {
      padding: 15px;
      min-height: unset;
    }
    .w-h-default-button.dialog-button {
      max-width: 45%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .mat-accent.dialog-button {
      max-width: 45%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
