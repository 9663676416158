// APP

@mixin material-data-table-mixin($theme) {
  $primary: map-get($theme, color, primary);
  $accent: map-get($theme, color, accent);
  $warn: map-get($theme, color, warn);
  .material-data-table {
    .material-data-table-container {
      // margin-bottom: $padding__page;
      .data-table-toolbar {
        height: 64px;
        padding-right: 8px;
        .left-side-wrapper {
          height: 100%;
        }
        .right-side-wrapper {
          text-align: end;
          align-self: center;
        }
      }

      table {
        width: 100%;
      }

      .material-icon {
        color: mat.get-color-from-palette($primary, 500);
      }

      .mat-mdc-row:hover {
        .material-icon {
          color: #fff;
        }
      }
    }
  }
}
