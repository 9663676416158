// APP

@mixin shift-calendar-crud-mixin($theme) {
    $primary: map-get($theme, color, primary);
    $accent: map-get($theme, color, accent);
    $warn: map-get($theme, color, warn);
    
    .shift-calendar-crud {
      margin: $padding__page;
      height: 100%;
    }
   
  }
  
  